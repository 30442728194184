<div class="card text-justify space-margin">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <h4>Payment Report</h4>
      </div>
    </div>
        <div class="row space-margin">
          <div class="col-md-4">
            <label>From Date <app-required></app-required></label>
            <app-datefield
              [(data)]="payment.fromdate"
              [placeholder]="'Please select date'"
              [maxDate]="today"
            ></app-datefield>
          </div>
          <div class="col-md-4">
            <label>To Date <app-required></app-required></label>
            <app-datefield
              [(data)]="payment.todate"
              [placeholder]="'Please select date'"
              [maxDate]="today"
            ></app-datefield>
          </div>
          <div class="col-md-4">
            <label>Mode of Payment <app-required></app-required></label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="payment.mode"
            />
          </div>
        </div>
        <div class="row space-margin">
         
          <div class="col-md-4">
            <label>Payment Status <app-required></app-required></label>
            <select
            (compareWith)="compareFn"
            multiple
            name="exam"
            class="custom-select"
            [(ngModel)]="payment.status"
          >
            <option *ngFor="let e of paymentStatus" [ngValue]="e">{{
              e.name
            }}</option>
          </select>
          </div>
          <div class="col-md-4">
            <label>Exam Name</label>
            <select
              (compareWith)="(compareFn)"
              multiple
              name="exam"
              class="custom-select"
              [(ngModel)]="payment.exam"
            >
              <option *ngFor="let e of exam" [ngValue]="e">{{
                e.exam_name
              }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label>Report Type <app-required></app-required></label>
            <select class="form-control custom-select" placeholder="Please select Report Type" [(ngModel)]="payment.reporttype">
              <option [ngValue]="''" disabled> Summary View </option>              
              <option>Detail View</option>
            </select>
          </div>
        </div>
     
    </div>
  </div>
