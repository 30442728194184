<div class="row">
  <div class="col-md-12">
    <h2>User ID Definition</h2>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="row p-2">
      <div class="col-md-12">
        <label>Generate User ID</label>
      </div>
    </div>
    <div *ngIf="error">
      <label> <span style="color:red">{{error}}</span></label>
    </div>
    <div class="form-group">
      <div class="row p-2">
        <div class="col-md-2">
          <label class="form-check-label">
            <input type="radio" class="form-check-custom" value="sequential" name="sequential" [(ngModel)]="type">
            Sequential
          </label>
        </div>
        <div class="col-md-2">
          <label class="form-check-label">
            <input type="radio" class="form-check-custom" value="random" name="random" [(ngModel)]="type"> Random
          </label>
        </div>
      </div>

      <div class="row p-2">
        <div class="col-md-2">
          <label for="UserId">User ID Length<span style="color:red">*</span></label>
        </div>
        <div class="col-md-2 text-left">
          <input type="text" [(ngModel)]="useridlength" maxlength="2" (keypress)="isNumber($event)" class="form-control"
            name="UserId" />

        </div>
        <div class="col-md-2 text-right">
          <label for="PrefixValue">Prefix Value</label>
        </div>
        <div class="col-md-2">
          <input type="text" [(ngModel)]="prefixvalue" class="form-control" name="PrefixValue" />
        </div>
        <div class="col-md-2 text-right">
          <label for="SuffixValue">Suffix Value</label>
        </div>
        <div class="col-md-2 ">
          <input type="text" [(ngModel)]="suffixvalue" class="form-control" name="SuffixValue" />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-1">
          <button type="submit" class="btn btn-primary" (click)="onSave()">Submit</button>
        </div>
        <div class="col-md-1">
          <button type="submit" class="btn btn-danger" (click)="onReset()">Cancel</button>
        </div>
        <div class="col-md-8">
          <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}

          </ngb-alert>
          <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}

          </ngb-alert>
        </div>
      </div>
<div class="row">
  <div class="col-md-12">
    If using random option:  work with administrator to ensure random numbers are pre-generated on server.
  </div>
</div>

    </div>
  </div>
</div>
<div class="space-margin"></div>
<div class="row p-2">
  <app-alert [sender]="sender"></app-alert>
</div>