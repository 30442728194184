<div class="row" style="margin-bottom: 20px;">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h2>{{examid=="new" ? 'Add Exam' : 'Edit Exam'}}</h2>
        </div>
      </div>
     
    </div>
  </div>


  <ngb-tabset type="pills" [orientation]="currentOrientation"  customClass="tab-style ">
    <ngb-tab title="General Info"  >
      <ng-template ngbTabContent>
          <div class="card space-margin">
              <div class="card-body ">
                 
                <div class="col-md-12 ">
                  <div class="form-row">
                    <div class="col-md-6 mb-2">
                      <label>Exam Name <span style="color:red">*</span></label>
                      <input type="text" placeholder="Please enter the Exam Name" class="form-control" name="name" maxlength="50" [(ngModel)]="examDetails.exam_name">
                    </div>
                    <div class="col-md-6 mb-2">
                      <label>Exam Description <span style="color:red">*</span></label>
                      <textarea class="form-control" placeholder="Please enter Exam Description" maxlength="100" [(ngModel)]="examDetails.exam_desc" rows="2"></textarea>            
                    </div>
                  </div> 
                </div>
              </div>
            </div>
       <div class="card space-margin">
          <h5 class="card-header text-white bg-info ">Application Period <span style="color:red">*</span></h5>
              <div class="card-body">                  
                <div class="col-md-12">                                                                 
                      <div class="row">                
                          <div class="col-md-3" style="margin-top:38px;">                           
                            
                              <app-datefield [(data)]="examDetails.app_startdate"   
                              [holdFuture]="true"
                                              [placeholder]="'Start Date'"></app-datefield>


                          </div>
                           <div class="col-md-3">
                                  <ngb-timepicker 
                                  [meridian]="meridian" 
                                  (ngModelChange)="onAppStartTimeChanged(examDetails.app_starttime)" 
                                  [(ngModel)]="examDetails.app_starttime"></ngb-timepicker>
                          </div>
                          
                          <div class="col-md-3"style="margin-top:38px;">
                             
                              <app-datefield [(data)]="examDetails.app_enddate"  
                                [minDate]="examDetails.app_startdate" 
                                [holdFuture]="true"
                                [placeholder]="'End Date'"></app-datefield>
                          </div>
                           <div class="col-md-3">
                                  <ngb-timepicker 
                                  [meridian]="meridian" 
                                  (ngModelChange)="onAppEndTimeChanged(examDetails.app_endtime)" 
                                  [(ngModel)]="examDetails.app_endtime"></ngb-timepicker>
                           </div>
                      </div>                        
                    </div>
                  </div>
                </div>
           <div class="card space-margin">
              <h5 class="card-header text-white bg-info">Payment Period <span style="color:red">*</span></h5>
                  <div class="card-body">
                    <div class="col-md-12 ">                   
                          <div class="row">                
                              <div class="col-md-3" style="margin-top:38px;">
                               
                                  
                                  <app-datefield [(data)]="examDetails.payment_startdate"  
                                  [holdFuture]="true"
                                    [minDate]="examDetails.app_startdate" [placeholder]="'Start Date'"></app-datefield>
                              </div>
                              
                               <div class="col-md-3">
                                      <ngb-timepicker 
                                      [meridian]="meridian" 
                                      (ngModelChange)="onPaymentStartTimeChanged(examDetails.payment_starttime)" 
                                      [(ngModel)]="examDetails.payment_starttime"></ngb-timepicker>
                              </div>
                              
                              <div class="col-md-3" style="margin-top:38px;">
                               
                                  <app-datefield [(data)]="examDetails.payment_enddate"  
                                  [holdFuture]="true"
                                  [minDate]="examDetails.payment_startdate" 
                                  [placeholder]="'End Date'"></app-datefield>
                              </div>
                              
                               <div class="col-md-3">
                                      <ngb-timepicker 
                                      [meridian]="meridian" 
                                      (ngModelChange)="onPaymentEndTimeChanged(examDetails.payment_endtime)" 
                                      [(ngModel)]="examDetails.payment_endtime"></ngb-timepicker>
                               </div>
                          </div>    
                          
                        </div>
                      </div>
                    </div>
               <div class="card space-margin">
                  <h5 class="card-header text-white bg-info ">Fee Type <span style="color:red">*</span></h5>
                      <div class="card-body">
                        <div class="col-md-12 ">

                            <div class="form-group form-check">
                                <input type="radio" class="form-check-input"
                                value="categorywise" 
                                        name="category" 
                                        id="radio_category" 
                                      [(ngModel)]="examDetails.fee_type"
                                 id="category_wise">
                                <label class="form-check-label" for="category_wise">Category Wise</label>
                              </div>

                              <div class="form-group form-check">
                                  <input type="radio" class="form-check-input" 
                                  value="examwise" 
                                  [(ngModel)]="examDetails.fee_type" 
                                  id="exam_wise">
                                  <label class="form-check-label" for="exam_wise">Exam Wise</label>
                              
                              
                                  <input #fee *ngIf="examDetails.fee_type=='examwise'" maxlength="10"
                                  type="text"  (keypress)="validateFloatKeyPress(fee.value,$event);"(input)="notCopyPasteSpecialCharAndAlphabet($event)"
                                  class="col-md-2 form-control" 
                                  name="fee" 
                                  [(ngModel)]="examDetails.examwise_fee">
                              
                                </div>


                      
                        </div></div></div>
                      
                      <div class="row p-2">
                          <div class="col-md-5 text-right">
                            <button type="button" class="btn btn-primary" (click)="onSubmit()"><i class="far fa-save"></i>&nbsp; Save</button>
                          </div>
                          <div class="col-md-3">
                            <button type="button" class="btn btn-danger" (click)="onCancel()"><i class="fa fa-window-close"></i>&nbsp;  Cancel</button>
                          </div>
                          <div class="col-md-4">
                            <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
                  
                            </ngb-alert>
                            <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}
                  
                            </ngb-alert>
                        </div>
                        </div>     
                
      </ng-template>
    </ngb-tab> 
    <ngb-tab title="Tab Configuration" *ngIf="examid != 'new'">
      <ng-template ngbTabContent>
        <div style="margin-top: 20px;"> 
          <app-app-tab-sequence></app-app-tab-sequence>
          </div>
      </ng-template>
    </ngb-tab>
   
    <ngb-tab title="Exam Id Generation" *ngIf="examid != 'new' && examGenType=='examwise'">
      <ng-template ngbTabContent>
        <div style="margin-top: 20px;">
         <app-examid-defination></app-examid-defination>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Eligibility Info"  *ngIf="examid != 'new'">
      <ng-template ngbTabContent>
        <div style="margin-top: 20px;"> 
         <app-exam-costinfo [feeType]="examDetails.fee_type"></app-exam-costinfo>
         </div>

      </ng-template>
    </ngb-tab>
    <ngb-tab title="Candidate Application Configuration"  *ngIf="examid != 'new'">
        <ng-template ngbTabContent> <div style="margin-top: 20px;"> 
            <app-application-configuration></app-application-configuration>
            </div>
            
        </ng-template>
      </ngb-tab>
  </ngb-tabset>
  
   

  <app-alert [sender]="sender"></app-alert>
    
    
