<div class="row">
  <div class="col-md-12">
    <h1>Dashboard</h1>
  </div>
</div>




<div class="row">
  <!-- <div class="col-md-6">
    <app-widget [title]="'Reg Status'">
    <app-candidate-status></app-candidate-status>
  </app-widget>
  </div> -->
   <div class="col-md-6 scroll">
    <app-widget [title]="'Candidate Status'">
    <app-candidate-status-count></app-candidate-status-count>
  </app-widget>
  </div>

 
  <div class="col-md-6">
      <app-widget [title]="'Payment Status'">
    <app-payment-status [chartContainer]="'Payment-status'"  [chartOpts]="'PieChart'"></app-payment-status>
  </app-widget>
  </div>
</div>



<div class="row">
    <div class="col-md-6">
      <app-widget [title]="'Latest Registrations'">
      <app-list-data [listType]="'LATEST_CANDIDATES'" ></app-list-data>
    </app-widget>
    </div>

  </div>



