<div class="row">
  <div class="col-md-12">
    <h2>Security Authentication</h2>
  </div>
</div>
<div class="card p-12">
  <div class="card-body">
    <div class="col-md-12 text-center ">

      <table class="table table-bordered">

        <thead>
          <tr>
            <th scope="col">Screen Name</th>
            <th scope="col" colspan="2">OTP
              <div class="row">
                <div class="col-sm-6 text-center"><label>SMS</label></div>
                <div class="col-sm-6 text-center"><label>EMAIL</label></div>
              </div>
            </th>
            <th scope="col">Captcha Code</th>
          </tr>
        </thead>
        <tr>
          <td>New Registration </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isRegisterSms" (change)="checkValue('register')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isRegisterEmail" (change)="checkValue('register')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isRegisterCaptcha" (change)="checkValue('register')">
            </div>
          </td>
        </tr>
        <tr>
          <td>Forgot Password</td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isForgotPassSms"
                (change)="checkValue('forgotpassword')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isForgotPassEmail"
                (change)="checkValue('forgotpassword')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isForgotPassCaptcha"
                (change)="checkValue('forgotpassword')">
            </div>
          </td>
        </tr>

        <tr>
          <td>Login Page</td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isLoginSms" (change)="checkValue('login')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isLoginEmail" (change)="checkValue('login')">
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox" [(ngModel)]="isLoginCaptcha" (change)="checkValue('login')">
            </div>
          </td>
        </tr>
        
        <tr>
          <td>Admin Login Page</td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminLoginSms" (change)="checkValue('adminlogin')" >
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminLoginEmail" (change)="checkValue('adminlogin')" >
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminLoginCaptcha" (change)="checkValue('adminlogin')" >
            </div>
          </td>
        </tr>
        <tr>
          <td>Admin Forgot Password</td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminForgotPassSms" (change)="checkValue('adminforgotpassword')" >
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminForgotPassEmail" (change)="checkValue('adminforgotpassword')" >
            </div>
          </td>
          <td>
            <div>
              <input type="checkbox" name="checkbox"  [(ngModel)]="isAdminForgotPassCaptcha" (change)="checkValue('adminforgotpassword')" >
            </div>
          </td>
        </tr> 
      
       
      </table>
    </div>
    <div class="col-md-12">
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
      </ngb-alert>
      <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}
      </ngb-alert>
    </div>
  </div>