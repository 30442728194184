<div class="row space-margin">
  <div class="col-md-3">
    <h3>Candidate Details</h3>
  </div>
  <div class="col-md-3">
    <button class="btn btn-success float-right" (click)="downloadHallTicket()"><i class="fa fa-download"></i> Download
      Hall Ticket</button>
  </div>
  <div class="col-md-3">
    <button class="btn btn-success float-right" (click)="downloadTestHallTicket()"><i class="fa fa-download"></i> Download
     Test Hall Ticket (PDF)</button>
  </div>
  <div class="col-md-3">
    <button class="btn btn-danger float-right" (click)="downloadTestHallTicketWord()"><i class="fa fa-download"></i> Download
     Test Hall Ticket (Word)</button>
  </div>

  <div class="col-md-3">
      <button class="btn btn-primary float-right" (click)="verifyCandidate()"><i class="fa fa-question"></i> Candidate Verification</button>
  </div>
      <div class="col-md-3">
        <button class="btn btn-danger float-right" (click)="verifyPayment()"><i class="fa fa-rupee-sign"></i> Payment Verification</button>
  </div>
</div>
<div class="card space-margin">
  <div class="card-body">
<div class="row" *ngIf="candidate && !candidate._id">
  <div class="col-md-12"> 

    This candidate has not submitted application...
  </div>
</div>
    <div class="row space-margin">
      <div class="col-md-4">
        User ID : {{candidate.user_id}}
        </div>
        <div class="col-md-4">
          ExamId: {{candidate.user_exam_id}}
        </div>
    </div>
    <div class="row space-margin">
      <div class="col-md-4">
        <label class="font-weight-bold">Name: </label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details"> {{candidate.personal_details.title.name ?candidate.personal_details.title.name: ""}}
          {{candidate.personal_details.firstname}}
          {{candidate.personal_details.middlename ?candidate.personal_details.middlename: "" }}
          {{candidate.personal_details.lastname}}</label>
      </div>

      <div class="col-md-4">
        <label class="font-weight-bold">Gender: </label>&nbsp;
        <label
          *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.gender.name ? candidate.personal_details.gender.name: ""}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">Contry: </label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.permanent_address.country.name}}</label>
      </div>



    </div>
    <div class="row space-margin">
      <div class="col-md-4">
        <label class="font-weight-bold">Exam: </label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.exam.exam_name}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">Category: </label>&nbsp;
        <label
          *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.category.name ? candidate.personal_details.category.name: ""}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">State:</label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.permanent_address.state}}</label>
      </div>
    </div>


    <div class="row space-margin">
      <div class="col-md-4">
        <label class="font-weight-bold">Email Id: </label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.emailid?candidate.personal_details.emailid: " "}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">Mobile No: </label>&nbsp;
        <label
          *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.mobilenumber?candidate.personal_details.mobilenumber: " "}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">City:</label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.permanent_address.city}}</label>
      </div>
    </div>

    <div class="row space-margin">
      <div class="col-md-4">
        <label class="font-weight-bold">Test City: </label>&nbsp;
        <label *ngIf="candidate && candidate.candidate_test_location">{{candidate.candidate_test_location.city1.name}}
          {{candidate.candidate_test_location.city2.name}}</label>
      </div>

      <div class="col-md-4">
        <label class="font-weight-bold">Marital Status: </label>&nbsp;
        <label
          *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.marital_status.name ? candidate.personal_details.marital_status.name: ""}}</label>
      </div>
      <div class="col-md-4">
        <label class="font-weight-bold">Pincode:</label>&nbsp;
        <label *ngIf="candidate && candidate.personal_details">{{candidate.personal_details.permanent_address.pincode}}</label>
      </div>

    </div>
  </div>
</div>
<div class="card space-margin">
  <div class=card-body>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-secondary mr-3" (click)="onBack()"><i class="fas fa-arrow-circle-left"></i>
          Back</button>
      </div>
    </div>
  </div>
</div>