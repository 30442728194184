<div class="row">
    <!-- <ngb-alert type="success" (close)="successMessage = null">Saved Details</ngb-alert> -->
<div class="col-md-12">
    <h2>Exam Details</h2>
</div>
</div>
<app-home-card-config [contenttype]="'exam_details'">
    
</app-home-card-config>

