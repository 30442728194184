import { Component, OnInit } from "@angular/core";
import { AlertService, SettingsService } from "src/app/shared";
import { first } from "rxjs/operators";

@Component({
  selector: "app-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit {
  dataAvailable = false;
  bannerType: any;
  fileType: any = "image/jpg,image/jpeg,image/png";
  error: any;
  scrolling:any;
  poweredby:any;


  constructor(private settingsService: SettingsService,
    private alert: AlertService
    ) {}

  ngOnInit() {
    this.getAppHeader();

    this.dataAvailable = true;
  }

  getAppHeader() {
    this.settingsService
      .getSettings()
      .pipe(first())
      .subscribe(res => {
        this.bannerType = res["banner_type"] || "banner_text";
        this.scrolling = res["scroll"];
        this.poweredby = res['poweredby'];
      });
  }

  ondocError(e) {
    this.error = e;
  }

  onSelectionChange(bannerType) {
    this.settingsService
      .setHeaderPreference(bannerType)
      .pipe(first())
      .subscribe(res => {
      });
  }

  saveScrolling (){
    this.settingsService.setScroll (this.scrolling)
    .pipe (first())
    .subscribe (res=>{
      this.alert.s ("Updated scrolling");
    });
  }

  savePoweredBy (){
    this.settingsService.setPoweredBy (this.poweredby)
    .pipe (first())
    .subscribe (res=>{
      this.alert.s ("Updated poweredby");
    });
  }

}
