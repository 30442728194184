<app-payment-filter [payment]="payment"></app-payment-filter>
<div class="row" style="margin-top: 10px;">
  <div class="col-md-6">
    <button type="button" title="Edit" class="btn btn-primary btn-xs" (click)="getReport()">Show Report</button>&nbsp;
  </div>
  <div class="col-md-6" style="text-align: right;">
    <button type="button" title="Edit" class="btn btn-success btn-xs" (click)="download()">Download</button>&nbsp;
  </div>
</div>

<div>
  <h2>
  Total: {{model.length}}</h2>
</div>
<table class="table table-hover table-outline mb-0" style="background-color: white;">
  
  <tr>
 
  <th>UserId</th>
  <th>Exam</th> 
  <th>User Exam ID</th>
  <th>Name</th>
  <th>Email</th>
  <th>Mobile</th>
  <th>Gender</th>
  <th>Category</th>
  <th>Misc Category</th>
  <th>PwBD</th>
  <th>Fee</th>
  <th>Payment Status</th>
  <th>Txn</th>
  <th>Method</th>
</tr>
<tr *ngFor="let row of model">
  <td>{{row.user_id}}</td>
  <td>{{row.exam}}</td>
  <td>{{row.user_exam_id}}</td>
  <td>{{row.name}}</td>
  <td>{{row.email}}</td>
  <td>{{row.mobile}}</td>
  <td>{{row.gender}}</td>
  <td>{{row.category}}</td>
  <td>{{row.misccategory}}</td>
  <td>{{row.pwd}}</td>
  <td>{{row.fee}}</td>
  <td>{{row.pay_status}}</td>
  <td>{{row.txnid}}</td>
  <td>{{row.method}}</td>
</tr>
</table>