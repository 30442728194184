<div class="row">
    <div class="col-md-12">
        <h3> <i class="fas fa-plus" (click)="toggle()"> </i> Add custom css</h3>
    </div>
</div>
<div class="row" *ngIf="open">
    <div class="col-md-12">
        <textarea class="form-control" 
                        placeholder="css"  [(ngModel)]="templatecss" rows="5"></textarea>            
                 
    </div>
</div>
<div class="row"  *ngIf="open" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="col-md-12">
       <button class="btn btn-success" (click)="saveCss()">Save Css </button> 
    </div>
</div>


