<body class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card-group">
          <div class="card p-4">
            <div class="card-body">
              <div class="row p-2">
                <div class="col-md-12 text-center ">
                  <h2>User ID Definition</h2>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-12">
                  <label>Generate User ID</label>
                </div>
              </div>
              <div class="row p-2 ">
                <div class="col-md-6 text-left">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-control" name="optradio">Sequential
                  </label>
                </div>
                <div class="col-md-6 text-left">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optradio">Random
                  </label>
                </div>
              </div>

              <div class="row p-2">
                <div class="col-md-12 text-left">
                  <label for="UserId">User ID Length</label>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-10">
                  <input type="text" class="form-control" name="UserId" />
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-12">
                  <label for="PrefixValue">Prefix Value</label>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-10 ">
                  <input type="text" class="form-control" name="PrefixValue" />
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-12 text-left">
                  <label for="SuffixValue">Suffix Value</label>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-10 ">
                  <input type="text" class="form-control" name="SuffixValue" />
                </div>
              </div>
              <div class="row p-2">
                <div class="col-md-6 text-right">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
                <div class="col-md-6 text-left">
                  <button type="submit" class="btn btn-danger">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>