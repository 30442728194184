<div class="form-group">
  <div>
    <h2>Users</h2>
  </div>
</div>

<div class="form-group">
  <div class="row">
  <div class="col-md-3">
    <button class="btn btn-success btn-md" type="button" data-toggle="modal" (click)="onAddUsers()">
      <i class="fa fa-plus"></i> Add User
    </button>
  </div>

  </div>
  <div class="row space-margin">
    <div class="col-md-4">
     <input type="search" id="search" class="form-control" [(ngModel)]="filter.key" placeholder="Search">
     
    </div>
    <div class="col-md-4">
      <button  class="btn btn-success" (click)="unlockAllUser()" style="margin-right: 50px;">Unlock All User</button>
      <input type="checkbox" [(ngModel)]="filter.locked"
      class="form-check-input"> Locked
     </div>



   


    <div class="col-md-4" >
      <button  class="btn btn-success" (click)="getUserList()">Search</button>
    </div>
  
    </div>
</div>
<div class="row">
  <div class="col-md-12">
Total Users: {{userCount}}
    </div>
</div>
<div class="row">
  <div class="col-md-12">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
           
            <th>Email</th>
            <th>Phone</th>
            <th>User Type</th>
            <th>Role</th>           
            <th>Status</th>
            <th>Locked</th>
          <th style="text-align:right;">Action</th>
        </tr>
      </thead>
      <tr *ngFor="let u of userlist;" style="cursor:pointer">
        <td><a>{{u.email}}</a></td>
        <td><a>{{u.phone}}</a></td>        
        <td><a>{{u.usertype}}</a></td>
        <td><a>{{u.role ? u.role.name : 'N/A'}}</a></td>
        <td><a>{{u.deleted ? 'Deleted' : 'Active' }}</a></td>
        <td>{{u.locked ? 'Yes' : 'No' }}</td>
      
      

        <td style="text-align:right">
          <button type="button" (click)="open(u)" [ngbTooltip]="Edit" title="Edit" class="btn btn-primary btn-xs"><i
              class="fa fa-edit" style="cursor: pointer;"></i></button>&nbsp;
          <button type="button" (click)="deleteUserList(u)" [ngbTooltip]="Delete" title="Delete" class="btn btn-danger btn-xs"><i
              class="fa fa-trash" style="cursor: pointer;"></i></button>
          <span class="pull-right">&nbsp;&nbsp;</span>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="row"  *ngIf="userCount >= 25">
  <div class="col-md-12">
    <app-pagination
    [(currentPage)]="currentPage"
    [pageSize]="pageSize"
    [totalRecords]="userCount"
    (pageChanged)="fetchUserData($event)"></app-pagination>
  </div>
</div>