<div class="form-group">
  <div>
    <h2>Test Schedule Upload</h2>
    <div>Check the date format and time carefully. Date should be 'DD/MM/YYYY and time shoudl be 'hh:mm aa
    If you are using excel, both date and time must be prefixed with single quote '  so that it is treated as a text.</div>
    
  </div>
</div>
<div class="row" *ngIf="error" class="text text-danger">
  <div class="col-md-12">
    {{error}}
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <a href="../../../assets/test_schedule.xlsx">Sample Test Schedule Excel Sheet</a>
  </div>
</div>
<div class="row p-3">
  <div class="col-md-12 bg-white">

    <app-header-logo [msg]="'Drop excel file here'" [header]="" [url]="'testschedule'" [docType]="'test_schedule'"
      [img_width]="'200px'" [fileType]="fileType" [uploadType]="uploadType" (docError)="ondocError($event)"
      (docImport)="ondocImport($event)" [dropZoneId]="'mydropzone_testschedule'"></app-header-logo>


  </div>
</div>


<div *ngIf="dataAvailable" class="row" style="overflow: auto; height:300px;">
  <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Candidate User ID</th>
          <th>Candidate Exam ID</th>
          <th>Test Center ID</th>
          <th>Test Date</th>
          <th>Test Start Time</th>
          <th>Exam Duration (in Min)</th>
          <th>Reporting Duration (in min)</th>
          <th>Gate Closing</th>
          <th>Error</th>

        </tr>
      </thead>
      <tr *ngFor="let t of testscheduledata;" style="cursor:pointer">
        <td>{{t.can_userid}}</td>
        <td>{{t.can_examid}}</td>
        <td>{{t.testcenterid}}</td>
        <td>{{t.testdate}}</td>
        <td>{{t.teststarttime}}</td>
        <td>{{t.examduration}}</td>
        <td>{{t.reportingduration}}</td>
        <td>{{t.gate_closing}}</td>
        <td>
         <span style="color:red" *ngIf="t.error"> {{t.error}}</span>
        </td>


      </tr>
    </table>
  </div>
</div>

<div *ngIf="dataAvailable && errorCount == 0" class="row p-2">
  <div class="col-md-12 text-center text-success text-bold">
    <h5>Total {{dataCount}} records, Please click on confirm button to import.</h5>
    <button class="btn btn-success btn-md" type="button" (click)="onConfirm()">
      Confirm
    </button>
  </div>
</div>

<div *ngIf="dataAvailable && errorCount > 0" class="row p-2">
  <div class="col-md-12 text-center text-success text-bold">
    <h5>Total {{dataCount}} records, Total Errors {{errorCount}}. Fix error first</h5>
    
  </div>
</div>


<div class="col-md-6">
  <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}

  </ngb-alert>
  <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}

  </ngb-alert>
</div>