<div class="form-group">
  <div>
    <h2>Exam Details List</h2>
  </div>
</div>

<div class="form-group">
  <div>
    <button class="btn btn-success btn-md" type="button" data-toggle="modal" (click)="addExam()">
      <i class="fa fa-plus"></i> Add Exam
    </button>
  </div>
  <div class="row space-margin">
    <div class="col-md-4">
      <input type="search" id="search" class="form-control" [(ngModel)]="filter.key" placeholder="Search">
    </div>
    <div class="col-md-4">
      <button class="btn btn-success" (click)="getExamList()">Search</button>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="!exams|| exams.length == 0">
    No Exam List Data found
  </div>
</div>

<div class="row space-margin" *ngIf="examCount ">
  <div class="col-md-3">
    <div style="color: #B5B4B4;">
      <h4 style="color:gray">Total:<span class="badge ng-binding" style="font-size: 20px;">{{examCount || 0}}</span>
      </h4>
      <div class="col-sm-2 pull-left"> </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="examCount > 0">
  <div class="col-md-12">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Exam Name</th>
          <th>Regi Start Date</th>
          <th>Regi End Date</th>
          <th>Payment Mode</th>
          <th style="text-align:right;">Action</th>
        </tr>
      </thead>
      <tr *ngFor="let s of exams;">
        <td>{{s.exam_name}}</td>
        <td>{{getFormatDateTime(s.app_startdate,s.app_starttime)}}</td>
        <td>{{getFormatDateTime(s.app_enddate,s.app_endtime)}}</td>
        <td>{{s.fee_type}}</td>

        <td style="text-align:right">
          <button type="button" title="Edit" (click)="onEdit(s._id)" class="btn btn-primary btn-xs"><i
              class="fa fa-edit"></i></button>&nbsp;
          <button type="button" (click)="deleteExam(s._id)" title="Delete" class="btn btn-danger btn-xs"><i
              class="fa fa-trash"></i></button>
          <span class="pull-right">&nbsp;&nbsp;</span>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="examCount >= 25">
    <app-pagination [(currentPage)]="currentPage" [pageSize]="pageSize" [totalRecords]="examCount"
      (pageChanged)="fetchUserData($event)"></app-pagination>
  </div>
</div>