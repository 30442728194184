<div class="row">
  <div class="col-md-12">
    <h2>Candidate Category Wise Report </h2>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <app-reports-filter [(reportFilter)]="reportFilter"></app-reports-filter>
      </div>
    </div>
    <div class="row space-margin">
      <div class="col-md-12">
        <button class="btn btn-primary" (click)="getReportData()">Get Report</button>
      </div>
    </div>

    <div *ngIf="reportdetails && reportdetails.length >0 ">
      <div class="row space-margin" *ngFor="let report of reportdetails" style="margin-bottom: 20px;">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h3 style="color:blue;"><label> {{report.name}}</label> </h3>
            </div>
          </div>

          <table class="table table-hover table-outline mb-0" style="background-color: white;">
            <thead class="thead-default" style=" background-color: #c2cfd6;">
              <tr>
                <th><strong>General:</strong>{{report.GENERAL ?report.GENERAL : "0"}}</th>
                <th><strong>ST:</strong>{{report.ST ?report.ST : "0"}} </th>
                <th><strong>SC:</strong>{{report.SC ?report.SC : "0"}}</th>
                <th><strong>Other:</strong>{{report.OBC ?report.OBC : "0"}}</th>
                <th><strong>Total:</strong>{{report.GENERAL + report.ST + report.SC + report.OBC}}</th>
              </tr>
            </thead>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>