<div class="app flex-row align-items-center space-margin">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group" >
          <div class="card p-4" >
            
              <div *ngIf="!otp_required">
                <div class="card-group">
                  <div class="card p-4" style="border: none !important;">
                    <div *ngIf="!isValid">
                      <h3>{{label_text}}</h3>

                      <div *ngIf="error">
                        <label> <span style="color:red">{{error}}</span></label>
                      </div>

                      <div class="help-block text-success" *ngIf="error1">
                        {{error1}}
                      </div>



                      <div class="form-group space-margin">
                        <label for="username">Please enter Email/Phone<span style="color:red">*</span></label>
                        <div class="input-group space-margin">
                          <input type="text" [(ngModel)]="username" id="username" name="username" class="form-control">
                        </div>

                      </div>
                      <div class="co-sm-4">
                        <re-captcha *ngIf="siteKey" (resolved)="captchaResolved($event)" class="re-captcha-mobile"
                          siteKey="{{siteKey}}"></re-captcha>
                        <!-- <div class="help-block text-danger" *ngIf="error">
                            {{error}}
                          </div> -->
                      </div>

                      <div class="row space-margin">
                        <div class="col-6">
                          <button type="submit" class="btn btn-info px-4" (click)="onSubmitClick()" type="button">Submit</button>
                        </div>
                        <div class="col-6 morespace-margin">
                          <button class="btn btn-link px-0" type="button" (click)="onBackToLogin()">Back to
                          Login</button>
                          </div>
                     </div>
                     

</div>
                    <div *ngIf="isValid && !success_message" >
                      <h3>Forgot Password</h3>

                      <div class="form-group space-margin">
                        <label for="username">Enter New Password<span style="color:red">*</span></label>
                        <div class="input-group">
                          <input type="password" [(ngModel)]="password" placeholder="Password" id="password" name="password"
                            class="form-control">
                        </div>

                      </div>
                      <div class="form-group space-margin">
                        <label for="username">Confirm Password<span style="color:red">*</span></label>
                        <div class="input-group">
                          <input type="password" [(ngModel)]="confirm_password" placeholder="Confirm Password" id="confirm_password"
                            name="confirm_password" class="form-control">
                        </div>

                      </div>
                      <div class="help-block text-danger" *ngIf="error">
                        {{error}}
                      </div>


                      <div class="row space-margin">
                        <div class="col-md-12">
                          <button type="submit" class="btn btn-info px-4" (click)="onResetClick()" type="button">Reset
                            Password</button>
                        </div>

                      </div>

                    </div>

                    <div class="help-block text-success" *ngIf="success_message">
                      <h4> {{success_message}} </h4>&nbsp; &nbsp; <button class="btn btn-link px-0" type="button"
                        (click)="onLoginClick()">
                        <h4>Login Now</h4>
                      </button>
                    </div>



                  </div>

                </div>
              </div>
              <div *ngIf="otp_required">
                <div class="card-group">
                  <div style="border: none !important;">
                    <div>
                      <div class="form-group col-md-12" *ngIf="!success_message">
                        <label class="space-margin font-larger">OTP has been sent to your email/phone. If you have not
                          received OTP on email, Please check your spam.</label>
                        <div class="col-md-12 space-margin-left-negative space-margin">
                          <input type="text" [(ngModel)]="otp" id="otp" name="otp" class="form-control" placeholder="Enter OTP">
                          <button type="submit" class="btn btn-info px-4 top-margin" (click)="onOTPSubmit()"
                            type="button">Validate</button>&nbsp;
                          <button class="btn btn-info px-4 top-margin" (click)="onOTPResend()"
                            type="button">Resend OTP</button>
                        </div>
                        <div class="Valid help-block text-danger space-margin " *ngIf="otp_error">
                          {{otp_error}}
                        </div>
                        </div>
                        <div class="Valid help-block text-success space-margin " *ngIf="success_message">
                          {{success_message}}
                        </div>

                      
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- <div class="card-group text-center" *ngIf="success_message">
                <div class="card p-4">
                    <div class="Valid help-block text-success space-margin " >
                        {{success_message}}
                      </div>
                </div>
            </div> -->
          </div>
          <div class="card text-white py-5 d-md-down" style="width:44%;background-color:#001f3f;">
            <div class="card-body text-center p-5" style="background-color:#001f3f;">
              <div class="p-5">
                <h2>Admin Portal</h2>

              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row space-margin">

    </div>
  </div>