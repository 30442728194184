<div class="row">
  <div class="col-md-12">
    <h2>Email Sms Content</h2>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="form-group">
      <div class="row p-2">
        <div class="col-md-12 text-left">
          <label class="lable-brown"><h3>Event Name: {{eventname}}</h3></label>
        </div>
      </div>
      <div *ngIf="error">
          <label> <span style="color:red">{{error}}</span></label>
        </div> 
      <div class="row p-2">
        <div class="col-md-12">
          <div class="form-check">
            <label class="form-check-label">
              <input type="checkbox" class="form-control" class="form-check-input" 
                  [(ngModel)]="emailEnable"><b>Email Configuration</b>
            </label>
          </div>
        </div>
      </div>

      <div class="row p-2">     
        <div class="col-md-12">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Email Subject</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" [(ngModel)]="emailsubject">
                </div>
              </div>
          </div>
    </div>

      <div class="row p-2">     
          <div class="col-md-12">
              <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Send Gird Template</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" [(ngModel)]="emailtemplate">
                  </div>
                </div>
            </div>
      </div>

      <div class="row p-2">
        <div class="form-group">
          <div class="col-md-12">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-control" class="form-check-input" [(ngModel)]="smsEnable"><b>SMS Configuration</b>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-2">
        <div class="col-md-3">
          <div class="form-group">
            <label for="exampleFormControlSelect2">Tags</label>
            <select multiple class="form-control" size="11" style="height: 100%;" id="exampleFormControlSelect1">
                <option *ngFor="let s of tags;">{{s}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-9">
          <div class="container">
            <label>SMS Text</label>
            <textarea class="form-control" [(ngModel)]="smstxt" rows="7"></textarea>         
          </div>
        </div>
      </div>

      <div class="row p-2">
        <div class="col-md-5 text-right">
          <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-danger" (click)="onCancel()">Cancel</button>
        </div>
         
      </div>
      

    </div>
  </div>
</div>