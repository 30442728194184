import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { ReportsService } from 'src/app/shared';
import { first } from "rxjs/operators";
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  payment: any = {
    fromdate: '',
    todate: '',
    mode: '',
    status: '',
    reporttype: '',
    exam: ''
  };
  headers = [
    'orderid',
    'UserId',
    'Exam',
    'User Exam ID',
    'Name',
    'Email',
    'Mobile',
    'Gender',
    'Category',
    'Misc Category',
    'PwBD',
    'Fee',
    'Payment Status',
    'Txn',
    'Method'

  ];
  model:any = [];
  inprogress =false;

  constructor(private reportService: ReportsService) {
    this.payment.fromdate = moment().add (-1, 'day').toDate();
    this.payment.todate = moment().add (1, 'day').toDate();
  }

  ngOnInit() {
  }


  getReport (){

    if (this.inprogress){
      return;
    }
    var data  = Object.assign ({}, this.payment);
    data.fromdate = moment (data.fromdate).format ("YYYYMMDD");
    data.todate = moment (data.todate).format ("YYYYMMDD");
    data.type = 'payment_report';
    this.inprogress = true;
    this.reportService.getReport (data)
    .pipe (first())
    .subscribe ( (res:any)=>{
      this.model = res;
      this.inprogress = false;
    
    });
  }

  getRow  (currRow){
    var ans = [];
 
    ans.push (currRow.orderid);
    ans.push (currRow.user_id);
    ans.push (currRow.exam);
    ans.push (currRow.user_exam_id);
    ans.push (currRow.name);
    ans.push (currRow.email);
    ans.push (currRow.mobile);
    ans.push (currRow.gender);
    ans.push (currRow.category);
    ans.push (currRow.misccategory);
    ans.push (currRow.pwd);
    ans.push (currRow.fee);
    ans.push (currRow.pay_status);
    ans.push (currRow.txnid);
    ans.push (currRow.method); 

    return ans;
  }

  getRows (){
    var rows = [];
    if (!this.model){
      return rows;
    }

    for (var i=0; i<this.model.length; i++){
      rows.push (this.getRow (this.model [i]));
    }
   return rows;
  }

  download (){
    if (!this.model){
      return;
    }
 
    this.reportService.exportAsCsv (this.headers, this.getRows(), "payment_report.csv");
  }


}
