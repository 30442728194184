<div class="modal-header">
  <h4 class="modal-title">{{userData._id ? 'Edit User' : 'Add User'}}</h4>
</div>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
          <div class="row p-2">
              <div *ngIf="error">
                  <label> <span style="color:red">{{error}}</span></label>
              </div>
          </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="form-group">
              <label for="eventcode">Email ID<span style="color:red">*</span></label>
              <input type="text" placeholder="Please enter the Email ID" [(ngModel)]="userData.email" class="form-control"  [readonly]="userData._id">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="eventcode">Phone Number<span style="color:red">*</span></label>
              <input type="text" placeholder="Please enter the Phone Number" maxlength="10" (keypress)="isNumber($event)" [(ngModel)]="userData.phone" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="role">Role</label>
              <select name="Role"
              [compareWith]="compareFn"   
               class="custom-select form-control" [(ngModel)]="userData.role" [disabled]="false">
                  <option [ngValue]="''" disabled selected> Please enter Role </option>
                  <option *ngFor="let r of roleResult" [ngValue]="r">{{r.name}}</option>
                </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="eventcode">User Type<span style="color:red">*</span></label>
              <select class="form-control custom-select" placeholder="Please enter the User Type" [(ngModel)]="userData.usertype">
                  <option [ngValue]="''" disabled selected> Please enter the User Type </option>              
                <option>admin</option>
                  <option>candidate</option>
                  <option>manager</option>
                  <option>customer</option>
                  <option>testcenter</option>
                  <option>exam</option>
                </select>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-3">  
              </div>
          <div class="col-md-3">
              <div class="form-group">
              <label for="disable"><span style="color:white">*</span></label>
            <input type="checkbox" class="form-control" class="form-check-input" [(ngModel)]="userData.disabled">Disabled
            </div>
          </div>
          <div class="col-md-3">            
              <label for="locked"><span style="color:white">*</span></label>
              <input type="checkbox" class="form-control" class="form-check-input" [(ngModel)]="userData.locked">Locked
          </div>
          <div class="col-md-3">  
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-right">

            <button type="reset" class="btn btn-primary" (click)="onSave()"><i class="far fa-save"></i>&nbsp;Save</button>
          </div>
          <div class="col-md-6">

            <button type="button" class="btn btn-secondary" (click)="onClose()"><i class="fa fa-window-close"></i>&nbsp;Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
    
      </ngb-alert>
      <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}
    
      </ngb-alert>
    </div>
</div>
<div class="space-margin"></div>
<div class="row p-2">
  <app-alert [sender]="sender"></app-alert>
</div>
