<div class="rm-widget card">

  <div class="card-header col-md-12 bg bg-primary rm-widget-header text text-white">
      {{title}}
  </div>
 
  <div class="card-body">
<div class="row rm-widget-body">
    <div class="col-md-12">
      <ng-content></ng-content>
    </div>
</div>
 

</div>
</div>

