<div class="input-group mb-3">
    <input type="search" id="search" 
        class="form-control" 
            [(ngModel)]="filter.key" placeholder="Search">
    <div class="input-group-append" 
                (click)="searchClick()">
      <button class="btn btn-primary">
        <i class="fa fa-search"></i>
      </button>
       
    </div>
  </div>