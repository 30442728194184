<div class="row">
  <div class="col-md-12">
    <h2>Application Verification List</h2>
  </div>
</div>
<div class="card p-12">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-4">
        <label for="example"> Exam Name <span style="color:red">*</span></label>
      </div>
      <div class="col-md-4">
        <label for="exam"> Verification Status<span style="color:red">*</span></label>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-4">
        <select name="exam" class="custom-select" [(ngModel)]="examid">
          <option [ngValue]="undefined" disabled selected> Please select Exam</option>
          <option *ngFor="let e of examlist;" value={{e._id}}>{{e.exam_name}}</option>

        </select>
      </div>
      <div class="col-md-4">
        <select name="verificationstatus" class="custom-select" [(ngModel)]="appstatus">
          <option [ngValue]="undefined" disabled selected> Please select Status</option>

          <option *ngFor="let s of verificationStatusResult;" value={{s.code}}>{{s.name}}</option>

        </select>
      </div>
      <div class="col-md-4">
        <button class="btn btn-success" (click)="getApplicationReport()">Search</button>
      </div>
    </div>

    <div class="row space-margin" *ngIf="applist && applist.length == 0">
      <div class="col-md-12">
        No Records found
      </div>
    </div>

    <div class="space-margin"> </div>
    <div class="row">
      <div class="col-md-12 ">
        <table class="table table-bordered" *ngIf="userCount">
          <thead >
            <tr>
              <th scope="col">Candidate Name</th>
              <th scope="col">Status</th>
              <th scope="col">Email Id</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">City</th>
              <th scope="col">View Application</th>
            </tr>
          </thead>
          <tr *ngFor="let pr of applist">
            <td>{{pr.candetail.firstname}} {{pr.candetail.lastname}}
            </td>
            <td>{{pr.status_code}}</td>
            <td>{{pr.candetail.emailid}}</td>
            <td>{{pr.candetail.mobilenumber}}</td>
            <td>{{pr.candetail.communication_address?pr.candetail.communication_address.city: ''}}</td>


            <td> <a href="/#/admin/application-verification-view/{{pr.candetail.userid}}/{{examid}}">View</a></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="userCount >=25" >
      <div class="col-md-12">
        <app-pagination [(currentPage)]="currentPage" [pageSize]="pageSize" [totalRecords]="userCount"
          (pageChanged)="fetchUserData($event)"></app-pagination>
      </div>
    </div>
  </div>

  