import { Component, OnInit } from "@angular/core";
import * as moment from "moment"; 
import { ReportsService } from "src/app/shared";
import { first } from "rxjs/operators";
@Component({
  selector: "app-candidate",
  templateUrl: "./candidate.component.html",
  styleUrls: ["./candidate.component.scss"]
})
export class CandidateComponent implements OnInit {
  
  filter: any = {
    fromdate: '',
    todate: '',
    regstatus: '',
    reporttype: '',
    exam: ''
  };

  model:any  = {
    rows: []
  };

  constructor(private reportService: ReportsService
  ) {}

  ngOnInit() {
    this.filter.fromdate = moment().add (-1, 'day').toDate();
    this.filter.todate = moment().toDate();
  }



  getReport (){

    var data  = Object.assign ({}, this.filter);
    data.fromdate = moment (data.fromdate).format ("YYYYMMDD");
    data.todate = moment (data.todate).format ("YYYYMMDD");
    data.type = 'candidate_report';

    this.reportService.getReport (data)
    .pipe (first())
    .subscribe ( (res:any)=>{
      this.model = res;
    
    });
     
  }

  download (){
    if (!this.model || !this.model.headers){
      return;
    }
    this.reportService.exportAsCsv (this.model.headers, this.model.rows, "candidates.csv");
  }


  

}
