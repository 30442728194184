<div class="row">
  <div class="col-md-4">
   <label>From Date</label>
    <app-datefield [(data)]="reportFilter.fromdate" [placeholder]="'From Date'"
      (onModelChange)="onModelChange($event)"></app-datefield>
  </div>
  <div class="col-md-4">
    <label>To Date</label>
    <app-datefield [(data)]="reportFilter.todate" [placeholder]="'To Date'" 
            (onModelChange)="onModelChange($event)"
      ></app-datefield>
  </div>
</div>

<div class="row  space-margin">
  <div class="col-md-4">
      <label>Exam Name</label>
      <select 
      (compareWith)="compareFn"
           multiple name="exam" 
          class="custom-select" 
          [(ngModel)]="reportFilter.examname">
        <option *ngFor="let e of exam;" [ngValue]="e">{{e.exam_name}}</option>
      </select>
    </div>

</div>