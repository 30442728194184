import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService, CaptchaService } from "src/app/shared";
import { first } from "rxjs/operators";

@Component({
  selector: "app-login",
  styleUrls: ['./login.component.scss'],
  templateUrl: "./login.component.html"
})
export class LoginComponent {
  error: any;
  captcha: any;
  siteKey: any;
  otp: any;
  otp_required: boolean;
  isOtpExpired: boolean;
  loginResp: any;
  username: any;
  password: any;
  errorm: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private captchaService: CaptchaService
  ) {}

  ngOnInit() {
    this.captchaService
      .getCaptcha("adminlogin")
      .pipe(first())
      .subscribe(res => {
        this.siteKey = res["sitekey"];
      });
  }

  onChangepass() {
    this.router.navigate(["home/changepass"]);
  }

  onLoginSubmit() {
    this.error = null;
    this.errorm = null;

    if (!this.password && !this.username) {
      this.error = "Please enter valid Username and Password";
      return;
    }
    if (this.siteKey && !this.captcha) {
      this.error = "Please resolve Captcha";
      return;
    }
    if (!this.username) {
      this.error = "Please enter Username";
      return;
    }
    if (!this.password) {
      this.error = "Please enter Password";
      return;
    }

    this.authenticationService
      .login(this.username, this.password, this.captcha, "adminlogin")
      .pipe(first())
      .subscribe(res => {
        // MAP with language
        if (this.siteKey) {
          this.captcha = res;
        }
        if (res["status"] == "error") {
          this.error = res["message"];
          return;
        }

        if (res["error"]) {
          this.error = res["message"]["english"];
          return;
        }
        if (!res["temp"]) {
          this.authenticationService.saveLogin(res);
          this.router.navigate(["/admin/dashboard"]);
        } else {
          this.otp_required = true;
          this.loginResp = res;
        }
      });
  }

  onOTPResend() {
    this.errorm = null;
    this.otp = null;

    this.authenticationService
      .resendOTP(this.loginResp.token, "adminlogin")
      .pipe(first())
      .subscribe(res => {
        this.isOtpExpired = false;
      });
  }

  onOTPSubmit() {
    this.errorm = null;
    if (!this.otp) {
      this.errorm = "Please enter otp";
      return;
    }

    this.authenticationService
      .validateOTP(this.otp, this.loginResp.token)
      .pipe(first())
      .subscribe(res => {
        this.isOtpExpired = false;

        if (res["error"]) {
          this.errorm = res["message"]["english"];
          if (res["error"] === 5006) {
            this.isOtpExpired = true;
          }
        } else {
          this.authenticationService.saveLogin(res);
          this.router.navigate(["/admin/dashboard"]);
        }
      });
  }

  onForgotpass() {
    this.router.navigate(["/pages/forgotpass"]);
  }

  captchaResolved(e) {
    this.captcha = e;
    this.error = null;
  }

  onRegister() {
    this.router.navigate(["home/register"]);
  }

  ngOnDestroy() {}
}
