<div class="row">
    <div class="col-md-6 offset-md-3">
  
      <div class="card">
        <div class="card-body text-center">
          <div class="form-group">
            <div class="row p-2">
              <div class="col-md-12 text-left text-info">
                <h2> New User Registration </h2>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-12 text-left">
                <h6>Please enter correct email id and mobile number as same will be used for future communication</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left"> 
                <label for="email">Email ID*</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <input type="text" class="form-control" name="email">
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-12 text-left">
                <label for="confirmEmail">Confirm Email ID*</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 float-none">
                <input type="text" class="form-control" name="confirmEmail">
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-12 text-left">
                <label for="mobileNum">Mobile Number*</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <input type="text" class="form-control" name="mobileNum">
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-12 text-left">
                <label for="confirmMobileNum">Confirm Mobile Number*</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <input type="text" class="form-control" name="confirmMobileNum">
              </div>
            </div>
  
            <div class="row p-2">
              <div class="col-md-12 text-left"> 
                <label for="password">Password*</label>
               </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <input type="password" class="form-control" name="password">
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-12 text-left">
                <label for="confirmPassword">Confirm Password*</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <input type="password" class="form-control"  name="confirmPassword">
              </div>
            </div>
            <div class="row p-5">
              <div class="col-md-6 float-left">
                <button type="submit" class="btn btn-block btn-info">Submit</button>
              </div>
              <div class="col-md-6 float-right">
                <button type="submit" class="btn btn-block btn-info">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
  