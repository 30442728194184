<div class="row">
    <div class="col-md-12">
      <h2>Test City Wise Report </h2>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <app-reports-filter [(reportFilter)]="reportFilter"></app-reports-filter>
        </div>
      </div>
      <div class="row space-margin">
        <div class="col-md-12">
          <button class="btn btn-primary" (click)="getReportData()">Get Report</button>
        </div>
      </div>
    </div>
  </div>