import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private path = '/reports';

  constructor(private backendService:BackendService) { }


  getReport (data:any){
    return this.backendService.post (this.path, data);
  }

  exportAsCsv (headers:any, rows:any, filename:any){
    var content = '';
    for (var i=0; i<headers.length; i++){
      content += headers [i];
      if (i!= headers.length -1){
        content += ",";
      }
    }

    content += "\n";

    for (var i=0; i<rows.length; i++){
      var cols = rows [i]; 
      for (var j=0; j<cols.length; j++){
        content += cols [j];
        if (j!= cols.length -1){
          content += ",";
        } 
      }
      content += "\n";
     
    }


    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

}
