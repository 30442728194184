import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {PagesRoutingModule} from './pages-routes.module';
import {LoginComponent} from './login/login.component';
import {ForgotpassComponent} from './forgotpass/forgotpass.component';
import {RegisterComponent} from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UseriddefinitionComponent } from './useriddefinition/useriddefinition.component'; 
import { ChangepassComponent } from './changepass/changepass.component' ; 
import {SharedModule} from '../shared/shared.module';

import { RecaptchaModule } from 'ng-recaptcha';
 
@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent, 
    UseriddefinitionComponent,   
    ForgotpassComponent,
    ChangepassComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    RecaptchaModule
  ]
})
export class PagesModule { }
