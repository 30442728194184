<app-candidate-report [filter]="filter"></app-candidate-report>

<div class="row" style="margin-top: 10px;">
  <div class="col-md-6">
    <button type="button" title="Edit" class="btn btn-primary btn-xs" (click)="getReport()">Show Report</button>&nbsp;
  </div>
  <div class="col-md-6" style="text-align: right;">
    <button type="button" title="Edit" class="btn btn-success btn-xs" (click)="download()">Download</button>&nbsp;
  </div>
</div>
 
<div>
  <h2>
  Total: {{model.rows.length}}</h2>
</div>
<table class="table table-hover table-outline mb-0" style="background-color: white;">
  
  <tr>
  <th>Payment</th>
  <th>UserId</th>
  <th>Name</th>
  <th>Email</th>
  <th>Mobile</th>
</tr>
<tr *ngFor="let row of model.rows">
  <td>{{row[0]}}</td>
  <td>{{row[3]}}</td>
  <td>{{row[4]}}</td>
  <td>{{row[5]}}</td>
  <td>{{row[6]}}</td>
</tr>
</table>