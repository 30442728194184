<div class="row">
  <div class="col-md-12">
    <h2>PDF Download Count</h2>
  </div>
</div>



<div class="row space-margin" style="margin-bottom: 20px;">
  <div class="col-md-4">
   <input type="search" id="search" class="form-control" [(ngModel)]="filter.key" placeholder="Search">
   
  </div>
  



 


  <div class="col-md-4" >
    <button  class="btn btn-success" (click)="getPdfStatus()">Search</button>
  </div>

  </div>


<div class="row space-margin">
  <div class="col-md-12" *ngIf="!candstatus|| candstatus.length == 0">
    No Records found
  </div>
</div> 

<div class="row">
  <div class="col-md-12 text-center ">

    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;" *ngIf="candstatus.length > 0">
        <tr>
          <th scope="col">Exam Name</th>
          <th scope="col">Email ID</th>
          <th scope="col">User ID</th>
          <th scope="col">Count</th>
          <th scope="col">Latest</th>
        </tr>
      </thead>
      <tr *ngFor="let t of candstatus">
        <td>{{t.examname}}</td>
        <td>{{t.email}}</td>
        <td>{{t.user_id}}</td>
        <td>{{t.count}}</td>
        <td>{{getDate(t)}}</td>
      </tr>

    </table>
  </div>
</div>
<div class="row"  *ngIf="statusCount >= pageSize">
  <div class="col-md-12">
    <app-pagination [(currentPage)]="currentPage" [pageSize]="pageSize" [totalRecords]="statusCount"
      (pageChanged)="fetchStatusData($event)"></app-pagination>
  </div>
</div>

