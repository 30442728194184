<div class="form-group">
    <div>
      <h2>Educational Details</h2>
      <div>
        These are displayed in order as highest level of education in "education details" tab of registration portal. System already creates these entries.
        You should use this screen to add more education or delete existing ones.
      </div>
    </div>
  </div>
  
  <div class="form-group">
    <div>
      <button class="btn btn-success btn-md" type="button" data-toggle="modal" (click)="onEduDetail()">
        <i class="fa fa-plus"></i> Add Educational Detail
      </button>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover table-outline mb-0" style="background-color: white;">
        <thead class="thead-default" style=" background-color: #c2cfd6;">
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Sequence</th>
            

            <th style="text-align:right;">Action</th>
          </tr>
        </thead>
        <tr *ngFor="let s of data;" style="cursor:pointer">
          <td>{{s.name}}</td>
          <td><a>{{s.code}}</a></td>
          <td>{{s.sequence}}</td>
          
          <td style="text-align:right">
            <button type="button" (click)="open(s)" [ngbTooltip]="Edit"  title="Edit" class="btn btn-primary btn-xs"><i
                class="fa fa-edit"></i></button>&nbsp;
            <button type="button" (click)="deleteData(s)" [ngbTooltip]="Delete" title="Delete" class="btn btn-danger btn-xs"><i
                class="fa fa-trash"></i></button>
            <span class="pull-right">&nbsp;&nbsp;</span>
          </td>
        </tr>
      </table>
    </div>
  </div>