import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserListService } from "../../shared/services";
import Swal from "sweetalert2";
import { first } from "rxjs/operators";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"]
})
export class UsersListComponent implements OnInit {
  userlist: any;
  error: string;

  filter: any = {
    key: ""
  };

  /*** for paginaton start */
  pageSize = 25;
  currentPage = 1;
  userCount = 0;

  /** end pagination */

  constructor(
    private router: Router,
    private userListServiceApi: UserListService
  ) {}

  ngOnInit() {
    this.getUserList();
  }

  open(user) {
    this.router.navigate(["admin/user/" + user._id]);
  }

  onAddUsers() {
    this.router.navigate(["admin/user/new"]);
  }

  getUserList() {
    this.userListServiceApi
      .getUserList(this.currentPage, this.pageSize, this.filter)
      .pipe(first())
      .subscribe(res => {
        this.userCount = res["count"];
        this.userlist = res["data"];
      });
  }

  unlockAllUser() {
    this.userListServiceApi
      .unlockAllUser()
      .pipe(first())
      .subscribe( res => {
        // console.log(res["message"])
        setTimeout(() => {
          this.getUserList();
        }, 1000);
      });
  }

  fetchUserData(e) {
    this.currentPage = e;

    this.getUserList();
  }

  deleteUserList(user) {
    Swal.fire({
      title: "Are you sure?",
      text: "User data will be deleted. This action cant be reversed!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it"
    }).then(result => {
      if (result.value) {
        Swal.fire("Deleted!", "User is deleted.", "success");

        this.userListServiceApi
          .deleteUserList(user._id)
          .pipe(first())
          .subscribe(res => {
            this.getUserList();
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "User is Not Deleted:)", "error");
      }
    });
  }
}
