<div class="row">
  <div class="col-md-12">
    <h2>Template Configuration for Download</h2>
  </div>
</div>

 

<div class="card">
  <div class="card-body">
   
   <app-template-css></app-template-css>
    <div class="row">
      <div class="col-md-3">
        <app-template-tab
          (onTabClicked)="onTabClicked($event)"
        ></app-template-tab>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <app-home-card-config-right
              [content]="content"
              (onContentChanged)="onContentChanged($event)"
            >
            </app-home-card-config-right>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card" style="margin-top:20px">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-primary mr-3" (click)="saveTabTemplate()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<app-template-doc-template></app-template-doc-template>
<div class="row p-2">
  <app-alert [sender]="sender"></app-alert>
</div>
