<div class="row">
    <div class="col-md-12">
        <h2>Registration Report</h2>
    </div>
</div>

<div class="row" *ngFor="let pr of details">
    <div class="col-md-12">

 
<div class="card p-12" >
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <h3 *ngIf="pr.personaldetail.firstname">{{pr.personaldetail.firstname + " " + pr.personaldetail.lastname}}</h3>
                <h3 *ngIf="!pr.personaldetail.firstname">{{pr.personaldetail.email}}</h3>
        
            </div>
            <div class="col-md-3">
                Phone:  {{pr.personaldetail.mobilenumber}}
             </div>
            <div class="col-md-3">
               UserID:  {{pr.user.userid}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                Gender:  {{pr.personaldetail && pr.personaldetail.gender?pr.personaldetail.gender.name: ''}}
             </div>
             <div class="col-md-3">
                Email:  {{pr.personaldetail.email || pr.personaldetail.emailid }}
             </div>
             <div class="col-md-3">
                DOB:  {{pr.personaldetail.dob? getDOB (pr.personaldetail.dob): '' }}
             </div>

        </div>

        <div class="row">
            <div class="col-md-3">
                Exam:  {{pr.exams && pr.exams.length > 0?pr.exams[0].exam_name: ''}}
             </div>
             <div class="col-md-3">
                Status:  {{pr.exams && pr.exams.length > 0?pr.exams[0].status_code: 'NOT_STARTED'}}
             </div>
             <div class="col-md-3">
                 
             </div>

        </div>

      
    </div>
</div>

</div>
</div>

 

<div class="row"  *ngIf="userCount >= pageSize">
    <div class="col-md-12">
      <app-pagination
      [(currentPage)]="currentPage"
      [pageSize]="pageSize"
      [totalRecords]="userCount"
      (pageChanged)="fetchUserData($event)"></app-pagination>
    </div>
  </div>