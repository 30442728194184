<div class="form-group">
  <div>
    <h2>Test Center Location</h2>
  </div>
</div>


<div class="form-group">
  <div class="row space-margin">
    <div class="col-md-4">
      <input type="search" id="search" class="form-control"[(ngModel)]="filter.key" placeholder="Search"
       >
    </div>
    <div class="col-md-2">
      <button class="btn btn-success" (click)="searchUploadTestCenterList()" ><i
          class="fa fa-search"></i>
        Search</button>
    </div>
    <div class="col-md-6">
      <button class="btn btn-success mr-3 float-right" type="button" data-toggle="modal" (click)="uploadTestcenter()" >
        <i class="fa fa-plus"></i> Upload Test center
      </button>
     <button class="btn btn-success mr-3 float-right" type="button" data-toggle="modal" (click)="onAdd()">
          <i class="fa fa-plus"></i> Add
        </button> 
    </div>
  </div>
</div>
<div class="row" *ngIf="!testcenter">
  <div class="col-md-12" >
    No TestCenter found
  </div>
</div>

<div class="row space-margin" >
  <div class="col-md-3">
    <div style="color: #B5B4B4;">
      <h4 style="color:gray">Total:<span class="badge ng-binding"
          style="font-size: 20px;">{{testcenterCount || 0}}</span></h4>
      <div class="col-sm-2 pull-left"> </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Seating Capacity</th>
            <th>Booked Seating Capacity</th>
            <th>Buffer Capacity</th>
            <th>Contact Number</th>
            <th>City</th>
            <th>State</th>
            </tr>



      </thead>
      <tr style="cursor:pointer" *ngFor="let t of testcenter;" (click)="onEdit(t._id)">
        <td>{{t.name}}</td>
        <td><a>{{t.testcenterid}}</a></td>
        <td><a>{{t.testcentercapacity}}</a></td>
        <td><a>{{t.bookedseatingcapacity}}</a></td>
        <td><a>{{t.buffercapacity}}</a></td>
        <td><a>{{t.contactnumber}}</a></td>
        <td><a>{{t.city}}</a></td>
        <td><a>{{t.state}}</a></td>     
        </tr>
    
    </table>
  </div>
</div>

<div class="row" *ngIf="testcenterCount >= 25">
  <div class="col-md-12">

    <app-pagination [(currentPage)]="currentPage" [pageSize]="pageSize" [totalRecords]="testcenterCount"
      (pageChanged)="fetchTestcenterData($event)"></app-pagination>
  </div>
</div>
 