<div class="container-fluid">
 
  <app-home-header ></app-home-header>
  <div class="row bottom-container">
    <div class="col-md-2">
      <app-main-menu></app-main-menu>
    </div>
    <div class="col-md-10">
      <app-main-body class="app-container"></app-main-body>
    </div>
  </div> 
  
</div>