<!-- <div class="card p-12">
  <div class="card-body">
    <div class="col-md-12 text-center ">
      <h2>Portal Confuguration</h2>
    </div>
    <div class="row p-2">
      <div class="col-sm-2">
        <label>Portal Language<span style="color:red">*</span></label>
      </div>
      <div class="col-md-5text-left">
        <div class="form-check-inline">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optradio">Only English
          </label>
        </div>
        <div class="form-check-inline">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optradio">Only Regional
          </label>
        </div>
        <div class="form-check-inline">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optradio">Bilingual
          </label>
        </div>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn btn-secondary">Add Language</button>
      </div>
      <div class="col-md-3">
        <button type="button" class="btn btn-primary">Update Language</button>
      </div>
    </div>
  </div>
</div>  -->







<div class="card p-12">
  <div class="card-body">
    <h2>Security Authentication<span style="color:red">*</span></h2>
  </div>
  <div class="row text-center">
    <div class="col-sm-12">
      <div class="table-responsive-md">
        <table class="table  table-bordered">
          <thead>
            <tr>
              <th scope="col-md">Screen Name</th>
              <th scope="col-sm " colspan="2">OTP
                <div class="row " colspan="2">
                  <hr>
                  <div class="col-6" text-left><label>SMS</label></div>
                  <div class="col-6"><label>EMAIL</label></div>
                </div>
              </th>
              <th scope="col-sm">Captcha Code</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>New Registartion</td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
            </tr>
            <tr>
              <td>Forgot Password</td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
            </tr>
            <tr>
              <td>Login Page</td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" name="checkbox">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="button" class="btn btn-primary flat-center">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row p-2 text-center">
            <div class="col-md-12 text-left">
              <label for="confirmemail">Sender Email ID<span style="color:red">*</span></label>
              <input type="text" class="form-control " placeholder="abcd@xyz.com" name="email">
            </div>
          </div>

          <div class="row p-2 text-center">
              <div class="col-md-12 text-left">
                <label for="confirmemail">Apply Theme<span style="color:red">*</span></label>
                <input type="text" class="form-control"placeholder="Select Theme" name="theme">
              </div>
            </div>

            <div class="row p-5">
                <div class="col-md-6 float-left">
                  <button type="submit" class="btn btn-block btn-primary">Save</button>
                </div>
                <div class="col-md-6 float-right">
                  <button type="submit" class="btn btn-block btn-danger">Cancel</button>
                </div>
              </div> -->