 
<div class="row bg-white">
  <div class="col-md-3"> 
    <img [attr.src]="logoUrl" height="75" width="200px" class="rm-banner"> 
  </div>
  <div class="col-md-5 rm-v-c rm-portal">
    Registration Management Portal
  </div>
  <div class="col-md-3 rm-v-c">
       Welcome {{user.email}}
  </div>
  <div class="col-md-1 rm-v-c"> 
   
    <div class="logout_link"
     (click)="logout()"><i class="fa fa-lock"></i> Logout</div>
  </div>
</div> 