<div class="form-group">
    <div>
      <h2>Import Test Center Locations</h2>
    </div>
  </div>
  <div class="row" *ngIf="error" class="text text-danger">
      <div class="col-md-12">
        {{error}}
      </div>      
    </div>
    <div class="row">
      <div class="col-md-12">
        <a href="../../../assets/test_center.xlsx" >Sample Test Center Excel Sheet</a>
      </div>
    </div>
   <div class="row p-3">
    <div class="col-md-12 bg-white">
        
    <app-header-logo [msg]="'Drop excel file here'" 
      [header]="" 
      [url]="'testcenter'" 
      [docType]="'test_center'" 
      [img_width]="'200px'"
      [fileType]="fileType"
      [uploadType]="uploadType"
      (docError)="ondocError($event)"
      (docImport)="ondocImport($event)"
      [dropZoneId]="'mydropzone_testcenter'"></app-header-logo>
    
    
    </div>
  </div>

  
<div *ngIf="dataAvailable" class="row" style="overflow: auto; height:300px;">
  <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Name</th>
          <th>ID</th>
          <th>Seating Capacity</th>
          <th>Booked Seating Capacity</th>
          <th>Buffer Capacity</th>
          <th>Contact Number</th>
          <th>City</th>
          <th>State</th>
          
        </tr>
      </thead>
      <tr *ngFor="let t of testcenterdata;" style="cursor:pointer" >
        <td>{{t.name}}</td>
        <td><a>{{t.testcenterid}}</a></td>
        <td><a>{{t.testcentercapacity}}</a></td>
        <td><a>{{t.bookedseatingcapacity}}</a></td>
        <td><a>{{t.buffercapacity}}</a></td>
        <td><a>{{t.contactnumber}}</a></td>
        <td><a>{{t.city}}</a></td>
        <td><a>{{t.state}}</a></td>     

      
      </tr>
    </table>
  </div>
</div>

<div *ngIf="dataAvailable" class="row p-2">
  <div class="col-md-12 text-center text-success text-bold"><h5>Total {{dataCount}} records,Please click on confirm button to import.</h5>
      <button class="btn btn-success btn-md" type="button" (click)="onConfirm()">
          Confirm
        </button>
    </div>  
  </div>
  <div class="col-md-6">
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
  
      </ngb-alert>
      <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}
  
      </ngb-alert>
      </div> 