<div class="row space-margin">
  <div class="col-md-12">
    <h3>Aplication Tab Management</h3>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">



        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Tab Name</th>
              <th scope="col">Display Tab Name</th>
              <th scope="col">Active</th>
              <th scope="col" colspan="2">Sequence</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tab of model.selectedTabsSequence">
              <td>{{tab.name}}</td>
              <td> {{tab.display}}</td>
              <td>
                <label>&nbsp;&nbsp;&nbsp;</label>
                <input type="checkbox" [(ngModel)]="tab.active" (click)="onCheckChange(tab)"
                  class="form-check-input">
              </td>
              <td>
                <button class="btn btn-success" *ngIf="tab.sequence > 1" (click)="up(tab)"><i
                    class="fa fa-angle-double-up"></i></button>

              </td>
              <td>
                <button class="btn btn-danger" *ngIf="tab.sequence > 0" (click)="down(tab)"><i
                    class="fa fa-angle-double-down"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row p-1">
          <div class="col-md-1">
            <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>


<div class="card space-margin">
  <div class="card-body">
    <div class="row p-2">

      <div class="col-md-3">
        <label class="form-check-label">
          <input type="radio" class="form-check-custom" [(ngModel)]="setting.selection" value="single"
            name="singleselect ">&nbsp; Single Select
        </label>
      </div>
      <div class="col-md-3 ">
        <label class="form-check-label">
          <input type="radio" class="form-check-custom" [(ngModel)]="setting.selection" value="multi"
            name="singleselect">&nbsp; Multi
          Select
        </label>
      </div>
    </div>
      <div class="row p-1">
        <div class="col-md-1">
          <button type="submit" class="btn btn-primary" (click)="onSave()">Save</button>
        </div>
      </div>
      <div class="row p-1">
        <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}

        </ngb-alert>
        <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}

        </ngb-alert>
      </div>
    
  </div>
</div>