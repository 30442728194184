<div class="form-group">
  
    <div  *ngIf="importCode==='STATE_IMPORT'">
      <h2>Import State</h2>
    </div>

    <div  *ngIf="importCode==='DISTRICT_IMPORT'">
      <h2>Import District</h2>
    </div>
  </div>
  <div class="row" *ngIf="error" class="text text-danger">
      <div class="col-md-12">
        {{error}}
      </div>
    </div>
    <div class="row  margin-bottom:10px;">
      <div class="col-md-12" *ngIf="importCode==='DISTRICT_IMPORT'">
        <a href="../../../assets/District.xlsx" >Download District Excel Sheet and upload</a>
      </div>

      <div class="col-md-12" *ngIf="importCode==='STATE_IMPORT'">
        <a href="../../../assets/state.xlsx" >Download State Excel Sheet and upload</a>
      </div>
    </div>
    <div class="row">
        <div class="col-md-4 text-center ">
            <div class="form-group">
                <select class="form-control custom-select"  (change)="onChange($event)" [(ngModel)]="importCode">
                        <option *ngFor="let list of importList"
                        value={{list.code}}>{{list.name}}</option>
                </select>
            </div>
        </div>
    </div>
   <div *ngIf="importCode==='STATE_IMPORT'" class="row p-3">
    <div class="col-md-12 bg-white">
        
    <app-header-logo [msg]="'Drop excel file here'" 
      [header]="" 
      [url]="'state_import'" 
      [docType]="'state_import'" 
      [img_width]="'200px'"
      [fileType]="fileType"
      [uploadType]="uploadType"
      (docError)="ondocError($event)"
      (docImport)="ondocImport($event)"
      [dropZoneId]="'mydropzone_state_import'"></app-header-logo>
    
    
    </div>
  </div>

  <div *ngIf="importCode==='DISTRICT_IMPORT'" class="row p-3">
    <div class="col-md-12 bg-white">
        
    <app-header-logo [msg]="'Drop excel file here'" 
      [header]="" 
      [url]="'district_import'" 
      [docType]="'district_import'" 
      [img_width]="'200px'"
      [fileType]="fileType"
      [uploadType]="uploadType"
      (docError)="ondocError($event)"
      (docImport)="ondocImport($event)"
      [dropZoneId]="'mydropzone_district_import'"></app-header-logo>
    
    
    </div>
  </div>

  
<div *ngIf="dataAvailable && importCode==='STATE_IMPORT'" class="row" style="overflow: auto; height:300px;">
  <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Country</th>
          <th>Country Code</th>
          <th>State</th>    
          <th>State Code</th>            
        </tr>
      </thead>
      <tr *ngFor="let t of importdata;" style="cursor:pointer" >
        <td>{{t.country}}</td>
        <td>{{t.country_code}}</td>
        <td>{{t.state}}</td>
        <td>{{t.state_code}}</td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="dataAvailable && importCode==='DISTRICT_IMPORT'" class="row" style="overflow: auto; height:300px;">
    <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
      <table class="table table-hover table-outline mb-0" style="background-color: white;">
        <thead class="thead-default" style=" background-color: #c2cfd6;">
          <tr>
            <th>State</th>
            <th>District</th>                
          </tr>
        </thead>
        <tr *ngFor="let t of importdata;" style="cursor:pointer" >
          <td>{{t.state}}</td>
          <td><a>{{t.district}}</a></td>
        </tr>
      </table>
    </div>
  </div>

<div *ngIf="dataAvailable" class="row p-2">
  <div class="col-md-12 text-center text-success text-bold"><h5>Total {{dataCount}} records,Please click on confirm button to import.</h5>
      <button class="btn btn-success btn-md" type="button" (click)="onConfirm()">
          Confirm
        </button>
    </div>  
  </div>
  <div class="col-md-6">
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
  
      </ngb-alert>
      <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}
  
      </ngb-alert>
      </div> 