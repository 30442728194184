import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
@Injectable({
  providedIn: 'root'
})
export class UserListService {

  private path = '/users'; 
  constructor(private backendService: BackendService ) { }

  saveUserList(userList) {     
    
    return this.backendService.post(this.path, userList);
  }

  getUserList(currPage:number, pageSize:number, filter:any) {
    
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };
    return this.backendService.post(this.path + "/search", data);
  }

  unlockAllUser() {
    return this.backendService.post(this.path + "/unlockAllUser", {message: "unlock all user"});
  }

  getUserDetails(userId) {
    
    return this.backendService.get(this.path + '/'+ userId);
  }

  deleteUserList(userid) {
    
    return this.backendService.get(this.path + '/'+ userid + '/delete');
  }

   
}
