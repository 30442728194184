<div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <h2>Hall Ticket Management</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-4">
          
         
          <input type="checkbox" [(ngModel)]="hallTicketAvailable" 
                  class="form-input form-check-input">
                  <label> &nbsp;Make Hall Ticket Available </label>

        </div>
        <div class="col-md-2">
          <button class="btn btn-primary" (click)="onAvailableClick()">Save</button>
        </div>
      </div>
    </div>
</div>


<div class="card">
  <div class="card-body">
      <div class="row sapce-margin">
        <div class="col-md-8">
          <div class="btn-group" role="group" aria-label="Updte info">
            <button
              type="button"
              [ngClass]="{
                'btn btn-primary': contentMode == 'LATER',
                'btn btn-secondary': contentMode != 'LATER'
              }"
              (click)="onBackgroundImg()"
            >
              <i class="fa fa-upload"></i> Upload Background Image
            </button>
            <button
              type="button"
              [ngClass]="{
                'btn btn-primary': contentMode == 'NOW',
                'btn btn-secondary': contentMode != 'NOW'
              }"
              (click)="onReportTemp()"
            >
              <i class="far fa-file"></i> Report Template
            </button>

            <button
            type="button"
            class="btn btn-success"
            (click)="onReportTemplate()"
          >
            <i class="far fa-file"></i> Report Doc Template
          </button>

          </div>
        </div>
      </div>


      <div class="row sapce-margin" *ngIf="contentMode == 'DOC_TEMPLATE'">
        <div class="col-md-12 ">
          <div class="card">
            <h5 class="card-header text-left">Upload Word Document</h5>
            <div class="card-body">
              <div class="col-md-6">
                <div class="row" *ngIf="errHall" class="text text-danger">
                  <div class="col-md-12">
                    {{ errHall }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 bg-white">
                    <app-header-logo
                      [msg]="'upload hall ticket word document'"
                      [header]="''"
                      [url]="'upload_hall_template'"
                      [docType]="'hall_template'"
                      [img_width]="'200'"
                      [img_height]="'180'"
                      [fileType]="wordType"
                      [uploadType]="'hall_ticket'"
                      (docError)="ondocHallError($event)"
                      [dropZoneId]="'mydropzone_hall_ticket'"
                      (docUploaded)="ondocUploaded($event)"
                    ></app-header-logo>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row sapce-margin" *ngIf="contentMode == 'LATER'">
        <div class="col-md-12 ">
          <div class="card">
            <h5 class="card-header text-left">Background Image</h5>
            <div class="card-body">
              <div class="col-md-6">
                <div class="row" *ngIf="errPhoto" class="text text-danger">
                  <div class="col-md-12">
                    {{ errPhoto }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 bg-white">
                    <app-header-logo
                      [msg]="'upload background image'"
                      [header]="''"
                      [url]="'uploadphoto'"
                      [docType]="'background_img'"
                      [img_width]="'200'"
                      [img_height]="'180'"
                      [fileType]="fileType"
                      [uploadType]="'photoimg'"
                      (docError)="ondocError($event)"
                      [dropZoneId]="'mydropzone_logo'"
                      (docUploaded)="ondocUploaded($event)"
                    ></app-header-logo>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row sapce-margin" *ngIf="contentMode == 'NOW'">
        <div class="col-md-12">
          <app-home-card-config-right
            [content]="content"
            (onContentChanged)="onContentChanged($event)"
          >
          </app-home-card-config-right>
        </div>
      </div>
    </div>
  </div>
  <div class="card sapce-margin" *ngIf="contentMode == 'NOW'">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-primary mr-4" (click)="saveHallTicket()">
            <i class="far fa-save"></i> Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-2">
    <app-alert [sender]="sender"></app-alert>
  </div>
  
  