import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { CoreService } from '../core/core.service';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {


  constructor(private authenticationService: AuthenticationService,
    private coreService: CoreService,
    private router: Router) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authReq = null;
    var isUserLoggedIn = this.authenticationService.isLoggedIn();
    var user = this.authenticationService.getUserDetails();
    var token = this.authenticationService.getAuthToken();

    if (!token && user) {
      token = user.ptoken;
    }
    if (isUserLoggedIn) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token,
          'appname': this.coreService.getAppName(),
          'usertype': this.coreService.getUserType()
        })
      });
    } else {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'appname': this.coreService.getAppName(),
          'usertype': this.coreService.getUserType()
        })
      });
    }


    // return next.handle(authReq).pipe(event => {
    //   console.log ("here is event ..", event);
    //   if (event instanceof HttpResponse) {
    //     console.log ("here is event  nody..", event.body);
    //     if (event.body &&  event.body.code == 403) {
    //       this.router.navigate(['/pages/login']);
    //       return;
    //     }
    //   }

    //   return event;
    // });

    return next
      .handle(authReq)
      .pipe(
        tap((ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
          //  console.log('Here is event ..', ev);
            if (ev.body && ev.body.code == 403) {
          //    console.log('Session Expired');
              this.router.navigate(['/pages/login']);
              return;
            }
          }
        })
      )


      

  }
}
