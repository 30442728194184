<div class="row">
    <div class="col-md-12">
        <h2>Application Verification Candidate View</h2>
    </div>
</div>

<div class="row space-margin"></div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Personal Details</u></label>
            </div>
        </div>

        <div class="row rm-mt-10">
            <div class="col-md-9 col-sm-12">

                <div class="row  rm-bt-10 rm-mt-10" *ngIf="application">
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label class="font-weight-bold">UserID </label>
                    </div>
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label>{{application.user_id}}</label>
                    </div>
                </div>

                <div class="row  rm-bt-10 rm-mt-10" *ngIf="application">
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label class="font-weight-bold">ExamID </label>
                    </div>
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label>{{application.user_exam_id}}</label>
                    </div>
                </div>


                <div class="row  rm-bt-10 rm-mt-10">
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label class="font-weight-bold">Candidate Name</label>
                    </div>
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label>{{profileResult.title?profileResult.title.name:''}} {{profileResult.firstname}}
                            {{profileResult.middlename}} {{profileResult.lastname}}</label>
                    </div>
                </div>
                <div class="row  rm-bt-10 rm-mt-10">
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label class="font-weight-bold">Date Of Birth</label>
                    </div>
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label>{{getDate(profileResult.dob)}}</label>
                    </div>
                </div>
                <div class="row  rm-bt-10 rm-mt-10">
                    <div class="col-md-4 col-sm-12 rm-mt-10">
                        <label class="font-weight-bold">Father's Name</label>
                    </div>
                    <div class="col-md-4 col-sm-12 rm-mt-10" *ngIf="profileResult.guardian">
                        <label> {{profileResult.guardian.title?profileResult.guardian.title.name:''}}
                            {{profileResult.guardian.firstname}} {{profileResult.guardian.middlename}}
                            {{profileResult.guardian.lastname}}</label>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-3">
                <label class="font-weight-bold">Gender</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult.gender?profileResult.gender.name:''}}</label>
            </div>
            <div class="col-md-3">
                <label class="font-weight-bold">Marital Status</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult.marital_status?profileResult.marital_status.name:''}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label class="font-weight-bold">Email ID</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult.emailid}}</label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <label class="font-weight-bold">Mobile Number</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult.mobilenumber}}</label>
            </div>
            <div class="col-md-3">
                <label class="font-weight-bold">Alternate Mobile Number</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult.alternate_mobilenumber}}</label>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3">
              <label>
                <b>Aadhaar Number:</b>
              </label>
            </div>
            <div class="col-md-3">
              <label>{{profileResult.aadhaar}}</label>
            </div>
          </div>


        <div class="row">
            <div class="col-md-3">
                <label class="font-weight-bold">Category</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult?.category?.name}}</label>
            </div>
          
        </div>


<div class="row" *ngIf="profileResult && profileResult.category && profileResult.category.code == 'UR'">
    <div class="col-md-3">
      <label class="font-weight-bold">Economically Weaker Section (EWS)</label>
    </div>
    <div class="col-md-3">
      <label>{{profileResult?.isews? 'Yes': 'No'}}</label>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-3">
      <label class="font-weight-bold">Apprenticeship Registration Number</label>
    </div>
    <div class="col-md-3">
      <label>{{profileResult?.apprenticeship_registration_number}}</label>
    </div>
  </div>
  



        <div class="row">
            <div class="col-md-3">
                <label class="font-weight-bold">PWD</label>
            </div>
            <div class="col-md-3">
                <label>{{profileResult?.ispwd? 'Yes': 'No'}}</label>
            </div>

            <div class="col-md-6" *ngIf="profileResult?.ispwd">
                 <table class="table table-hover">
                     <tr><th>Type</th><th>PWD %</th></tr>
                     <ng-container *ngFor="let t of profileResult.pwd_type">
                         
                     <tr *ngIf="t.selected">
                         <td>{{t.name}}</td><td>{{t.percentage}}</td>
                        
                        </tr>

                     </ng-container>
                     
                 </table>
            </div>
            
        </div>

        <div class="row" *ngIf="profileResult?.ispwd">
            <div class="col-md-3">
              <label class="font-weight-bold">Do you need scribe?</label>
            </div>
            <div class="col-md-3">
              <label>{{profileResult?.need_scribe ? 'Yes': 'No'}} </label>
            </div>
          </div>



      

        <div class="row space-margin"></div> 


        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Permanent Address of the Candidate</u></label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label class="font-weight-bold">Address</label>
            </div>
            <div class="col-md-10" *ngIf="profileResult.permanent_address">
                <label>{{profileResult.permanent_address.add1}} 
                    <div *ngIf="profileResult.permanent_address.add2">{{profileResult.permanent_address.add2}}</div>
                    <div  *ngIf="profileResult.permanent_address.add3"> {{profileResult.permanent_address.add3}}</div>
                   
                    
                    {{profileResult.permanent_address.pincode}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label class="font-weight-bold">Country</label>
            </div>
            <div class="col-md-2" *ngIf="profileResult.permanent_address">
                <label>
                    
                    {{getCountry (profileResult.permanent_address)}}
                     
                
                </label>
            </div>
            <div class="col-md-2 ">
                <label class="font-weight-bold">State</label>
            </div>
            <div class="col-md-2" *ngIf="profileResult.permanent_address">

                {{getState (profileResult.permanent_address)}}
              
            </div>
            <div class="col-md-2">
                <label class="font-weight-bold">District</label>
            </div>
            <!-- <div class="col-md-2" *ngIf="profileResult.communication_address">
                <label>{{profileResult.communication_address.district.district}}</label>
            </div> -->
            <div class="col-md-2" *ngIf="profileResult.permanent_address">
            
                {{getDistrict (profileResult.permanent_address)}}
            </div>

            <div class="col-md-2">
                <label class="font-weight-bold">City</label>
            </div>
          
            <div class="col-md-2" *ngIf="profileResult.permanent_address">
            
                {{ profileResult.permanent_address.city}}
            </div>



        </div>
        <div class="row space-margin"></div>
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Communication Address of the Candidate</u></label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label class="font-weight-bold">Address</label>
            </div>
            <div class="col-md-10" *ngIf="profileResult.communication_address">
                <label>{{profileResult.communication_address.add1}}
                    <div *ngIf="profileResult.communication_address.add2">{{profileResult.communication_address.add2}}</div>
                    <div  *ngIf="profileResult.communication_address.add3"> {{profileResult.communication_address.add3}}</div>
                   
                    {{profileResult.communication_address.pincode}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label class="font-weight-bold">Country</label>
            </div>
            <div class="col-md-2" *ngIf="profileResult.communication_address">

                {{getCountry (profileResult.communication_address)}}
                    
            </div>
            <div class="col-md-2">
                <label class="font-weight-bold">State</label>
            </div>
            <div class="col-md-2" *ngIf="profileResult.communication_address">
                <!-- <label>{{profileResult.communication_address.state}}</label> -->
                {{getState (profileResult.communication_address)}}

            </div>
            <div class="col-md-2">
                <label class="font-weight-bold">District</label>
            </div>
           
            <div class="col-md-2" *ngIf="profileResult.communication_address">
                {{getDistrict (profileResult.communication_address)}} 
            </div>

            <div class="col-md-2">
                <label class="font-weight-bold">City</label>
            </div>
           
            <div class="col-md-2" *ngIf="profileResult.communication_address">
                {{profileResult.communication_address.city}} 
            </div>



        </div>
    </div>
</div>

<div class="row space-margin"></div> 

<div class="card">
    <div class="card-body">
<div class="row">
    <div class="col-md-12">
        <label class="font-weight-bold text-info"><u>Exams</u></label>
    </div>
</div>
<div class="row" *ngFor="let exam of exams">
    <div class="col-md-6">
       Exam Name: {{exam.exam_name}}
    </div>
    <div class="col-md-6">
        Exam Status: {{exam.status_code}}
     </div>
</div>
</div>
</div>


<div class="row space-margin"></div>

<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Educational Details</u></label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 table-responsive-md">
                <table class="table table-bordered table-outline mb-0" style="background-color: white;">

                    <tr>
                        <th class="w-25">Level</th>
                        <th class="w-25">Major Subject</th>
                        <th class="w-25">Institute / University/School/Board</th>
                        <th class="w-25">Year of Passing</th>
                        <th class="w-25">Percentage</th>
                        <th class="w-25">Grade</th>
                    </tr>

                    <tr *ngFor="let t of eduList;">
                        <td>{{t.level_name}}</td>
                        <td>{{t.subject_name}}</td>
                        <td>{{t.institute}}</td>
                        <td>{{t.year_of_passing}}</td>
                        <td>{{t.percentage}}</td>
                        <td>{{fillGradeName(t)}}</td>
                    </tr>

                </table>
            </div>
        </div>
        <div class="row space-margin"></div>

        <div class="row ">
            <div class="col-md-12 table-responsive-md">
                <table class="table table-bordered table-outline mb-0" style="background-color: white;">

                    <tr>
                        <th class="w-25">Professional Qualification</th>
                        <th class="w-25">Institute / University</th>
                        <th class="w-25">Year of Passing</th>
                        <th class="w-25">Percentage</th>
                        <th class="w-25">Grade</th>
                    </tr>

                    <tr *ngFor="let t of profResult;">
                        <td>{{fillProfQualName(t)}}</td>
                        <td>{{t.instuniversity}}</td>
                        <td>{{t.yrofpass}}</td>
                        <td>{{t.percentage}}</td>
                        <td>{{fillGradeName(t)}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row space-margin"></div>

<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Occupational Details</u></label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" *ngIf="currentoccstatus.status">
                <label class="font-weight-bold">Current Employment Status : &nbsp;</label>
                <label> {{currentoccstatus.status.name}}</label>
            </div>
            <div class="col-md-6" *ngIf="currentoccstatus.status">
                <label class="font-weight-bold">No. of Years of Experience : &nbsp;</label>
                <label> {{currentoccstatus.noofexp}}</label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 table-responsive-md">
                <table class="table table-bordered table-outline mb-0" style="background-color: white;">

                    <tr>
                        <th class="w-25">Name of Organisation</th>
                        <th class="w-25">Designation</th>
                        <th class="w-25">Date To Current</th>
                        <th class="w-25">Place of work</th>
                    </tr>

                    <tr *ngFor="let t of occudetail;">
                        <td>{{t.name}}</td>
                        <td>{{t.designation}}</td>
                        <td *ngIf="t.currently_working == false">{{t.frommonth}}/{{t.fromyear}} -
                            {{t.tomonth}}/{{t.toyear}}</td>
                        <td *ngIf="t.currently_working == true">{{t.frommonth}}/{{t.fromyear}} - Current</td>
                        <td>{{t.work_city}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row space-margin"></div>


        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Document Uploaded</u></label>
            </div>
        </div>

        <div class="card" *ngFor="let doc of docList">
            <div class="card-body">

        <div class="row raisedbox" >
            <div class="col-md-3">
                <b>{{getDocName(doc.doctype)}}</b> 
            </div>

            <div class="col-md-3">
                <a target="_new" href="{{getUrl(doc._id)}}">view document</a>
            </div>
            <div class="col-md-3">
               
               <img *ngIf="doc.doctype == 'candidate_photo' || doc.doctype == 'candidate_signature'" src="{{getUrl(doc._id)}}" width="200px" height="200px" >
            </div>
        </div>


    </div>
</div>
<div class="row space-margin"></div>

<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Eligibility Criteria</u></label>
            </div>
        </div>
        <div class="row" *ngFor="let s of eligiblityResult">
            <div class="col-md-6">
                <label>{{s.name}}</label>
            </div>

            <div class="col-md-6" *ngIf="eligiblityResult && eligiblityResult.length > 0 ">
                <label>{{s.answer}}</label>
            </div>
        </div>
    </div>
</div>
<div class="row space-margin"></div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <label class="font-weight-bold text-info"><u>Prefered Test City</u></label>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <label class="font-weight-bold">Prefered Test City1</label>
                    </div>
                    <div class="col-md-2" *ngIf="cityResult && cityResult.city1">
                        <label>{{cityResult.city1.name}}</label>
                    </div>
                </div>
                <div class="row" space-margin>
                    <div class="col-md-6">
                        <label class="font-weight-bold">Prefered Test City2</label>
                    </div>
                    <div class="col-md-2" *ngIf="cityResult && cityResult.city2">
                        <label>{{cityResult.city2.name}}</label>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>
<div class="space-margin"></div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                Candidate's Application Status is
                <label class="font-weight-bold" style="color:red">
                     {{currentStatus}}  
                </label>
                <h3> {{userStatus?userStatus.reject_reason: ''}}</h3>

            </div>
        </div>
        <div class="row space-margin">

            <div class="col-md-2">
                <button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
            </div>
            <!-- <div class="col-md-2" *ngIf="currentStatus === 'APPLICATION_SUBMITED'">
                <button type="button" class="btn btn-primary" (click)="onApprove()">Approve</button>
            </div> -->
            <!-- <div class="col-md-2" *ngIf="currentStatus === 'APPLICATION_VERIFICATION_PENDING'">
                <button type="button" class="btn btn-primary" (click)="onApprove()">Approve</button>
            </div> -->

            <div class="col-md-2">
                <button type="button" class="btn btn-primary" (click)="onApprove()">Approve</button>
            </div>
              <div class="col-md-2">
                <button type="button" class="btn btn-danger" (click)="onReject()">Reject</button>
            </div> 
            <!-- <div class="col-md-2" *ngIf="currentStatus !== 'APPLICATION_VERIFICATION_REJECTED'">
                <button type="button" class="btn btn-danger" (click)="onReject()">Reject</button>
            </div> -->
            <div class="col-md-6">
                <div class="md-form">
                    <label for="form7">Reason for Rejection</label>
                    <textarea id="form7" [(ngModel)]="reject_reason" class="md-textarea form-control"
                        rows="3"></textarea>

                </div>
            </div>
        </div>
        <div>
            <div class="col-md-4">
                <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}

                </ngb-alert>
                <ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">{{ errorMessage }}

                </ngb-alert>
            </div>
        </div>

    </div>
</div>
<div class="row space-margin"></div>