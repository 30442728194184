import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-instructions',
  templateUrl: './candidate-instructions.component.html',
  styleUrls: ['./candidate-instructions.component.scss']
})
export class CandidateInstructionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
