import { Component, OnInit } from "@angular/core";
import { CandidateStatusCountService } from "../../../shared/services/candidate-status-count/candidate-status-count.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-candidate-status-count",
  templateUrl: "./candidate-status-count.component.html",
  styleUrls: ["./candidate-status-count.component.scss"]
})
export class CandidateStatusCountComponent implements OnInit {
  registeredCandidateCount: any;
  totalcount: any;
  appPendingStatus: any;
  appSubmittedStatus: any;
  totalPending: any;
  totalSubmitted: any;
  pen: any;
  sub: any;

  constructor(
    private candidateStatusCountService: CandidateStatusCountService
  ) {}

  ngOnInit() {
    this.getRegCandidate();
    this.getAppSubmittedStatus(); 
  }

  getRegCandidate() {
    this.candidateStatusCountService
      .getRegisteredCandidate()
      .pipe(first())
      .subscribe(res => {
        this.registeredCandidateCount = res;
        this.totalcount = this.registeredCandidateCount.total_count;
      });
  }

  getAppSubmittedStatus() {
    this.candidateStatusCountService
      .getAppSubmittedStatus()
      .pipe(first())
      .subscribe(res => {
        this.appSubmittedStatus = res;
        this.totalSubmitted = this.appSubmittedStatus.total_count;
      });
  }

  onSort(e) {}

   
}
