import { Component, OnInit, Input } from "@angular/core";
import { RegistrationReportService } from "src/app/shared";
import { first } from "rxjs/operators";
import {CoreService } from 'src/app/shared/services';
import { ConstantService } from "src/app/shared/services/constant/constant.service";
import * as moment from "moment"; 
@Component({
  selector: "app-candidate-report",
  templateUrl: "./candidate-report.component.html",
  styleUrls: ["./candidate-report.component.scss"]
})
export class CandidateReportComponent implements OnInit {
 
 @Input() filter:any = {

  };
  today = new Date();
  exam: any;
  appStatus: any;

  constructor(
    private registrationReportService: RegistrationReportService,
    private core: CoreService,
    private constantApi: ConstantService ) { }

  ngOnInit() {
    this.today = moment ().add (1, 'day').toDate ();
    this.getExam();
    this.getApplicationStatus();
  }

  getExam() {
    this.registrationReportService
      .getExam()
      .pipe(first())
      .subscribe(res => {
        this.exam = res;
      });
  }

  compareFn (a, b){
    return this.core.compareFn (a, b);
  }

  getApplicationStatus() {

    //CANDIDATESTATUS

    this.constantApi
    .getConstantValue("CANDIDATESTATUS")
    .pipe(first())
    .subscribe(res => {
      if (res) {
        this.appStatus = res; 
      }
    });

     
  }
}
