<div class="form-group">
  <div>
    <h2>Category</h2>
  </div>
</div>

<div class="form-group">
  <div>
    <button class="btn btn-success btn-md" type="button" data-toggle="modal" (click)="onAddCategory()">
      <i class="fa fa-plus"></i> Add Category
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Category</th>
          <th>Age</th>
          <th>Minimum Qualification</th>
          <th>Experience</th>

          <th style="text-align:right;">Action</th>
        </tr>
      </thead>
      <tr *ngFor="let s of categoryData;" style="cursor:pointer">
        <td>{{s.category}}</td>
        <td><a>{{s.age}}</a></td>
        <td>{{s.minqualification}}</td>
        <td><a>{{s.experience}}</a></td>
        <td style="text-align:right">
          <button type="button" (click)="open(s)" [ngbTooltip]="Edit" title="Edit" class="btn btn-primary btn-xs"><i
              class="fa fa-edit"></i></button>&nbsp;
          <button type="button" (click)="deleteData(s)" [ngbTooltip]="Delete" title="Delete" class="btn btn-danger btn-xs"><i
              class="fa fa-trash"></i></button>
          <span class="pull-right">&nbsp;&nbsp;</span>
        </td>
      </tr>
    </table>
  </div>
</div>