import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExamListService } from "../../shared/services/exam-list/exam-list.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-exam-costinfo",
  templateUrl: "./exam-costinfo.component.html",
  styleUrls: ["./exam-costinfo.component.scss"]
})
export class ExamCostinfoComponent implements OnInit {
  examCostInfo: any = [];
  examId: any;
  @Input() feeType: any;
  dir:any = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private examListService: ExamListService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.examId = params.get("id");
    });
    if (this.examId) {
      this.getExamCostInfo(this.examId);
    }
  }

  addcCostInfo() {
    this.router.navigate([
      "admin/exam-costinfo/" +
        this.examId +
        "/cost/" +
        "new" +
        "/type/" +
        this.feeType
    ]);
  }

  getExamCostInfo(examid) {
    console.log("in componenet", examid);
    this.examListService
      .getExamCostInfo(examid)
      .pipe(first())
      .subscribe(res => {
        this.examCostInfo = res;
        console.log("chk result", res);
      });
  }

  onEdit(id) {
    this.router.navigate([
      "admin/exam-costinfo/" +
        this.examId +
        "/cost/" +
        id +
        "/type/" +
        this.feeType
    ]);
  }

  onDelete(id) {
   
    this.examListService
      .deleteExamCostInfo(id)
      .pipe(first())
      .subscribe(res => {
        this.getExamCostInfo(this.examId);
      });
  }

  showTogether (){

    var data = this.examCostInfo;
    this.dir = -1 * this.dir;
    this.examCostInfo = data.sort ((a, b)=>{

      var acat  = a.category.name;
      var ag = a.gender.name;
      var ap = a.ispwd;

      var bcat  = b.category.name;
      var bg = b.gender.name;
      var bp = b.ispwd;

      if(acat != bcat){
        if (acat > bcat){
          return 1 * this.dir;
        }
        return -1 * this.dir;
        
      }

      if (ag != bg){
        

        if (ag > bg){
          return 1 * this.dir;
        }
        return -1 * this.dir;

      }
      if (ap != bp){
    

        if (ap > bp){
          return 1 * this.dir;
        }
        return -1 * this.dir;
      }
      return 1;

      

    });

  }

  export (){
    var url = this.examListService.export (this.examId);
    window.open (url);
  }
}
