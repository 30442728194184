<div class="form-group">
  <div>
    <h2>Language Configuration</h2>
    <div>codes will be made lowercase</div>
  </div>
</div>

<div class="form-group">
  <div>
    <button class="btn btn-success btn-md" type="button" data-toggle="modal" (click)="open(content,{})">
      <i class="fa fa-plus"></i> Add Language
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{currentLanguage._id ? 'Edit Language' : 'Add Language'}}</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="error">
      <label> <span style="color:red">{{error}}</span></label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="eventcode">Language Code<span style="color:red">*</span></label>
          <input type="text" maxlength="15" placeholder="Please enter Language Code"
            (input)="notCopyPasteSpecialCharAndNum($event)" (keypress)="isChar($event)"
            [(ngModel)]="currentLanguage.code" class="form-control">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="eventname">Language Name<span style="color:red">*</span></label>
          <input type="text" maxlength="15" placeholder="Please enter Language Name"
            (input)="notCopyPasteSpecialCharAndNum($event)" (keypress)="isChar($event)"
            [(ngModel)]="currentLanguage.name" class="form-control">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onSave()"><i class="far fa-save"></i>&nbsp;Save</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')"><i
          class="fa fa-window-close"></i>&nbsp;Close</button>
    </div>

  </div>
</ng-template>


<div class="row">
  <div class="col-md-12">
    <table class="table table-hover table-outline mb-0" style="background-color: white;">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Language Code</th>
          <th>Language Name</th>
          <th style="text-align:right;">Action</th>
        </tr>
      </thead>
      <tr *ngFor="let s of languages;" style="cursor:pointer">
        <td>{{s.code}}</td>
        <td><a>{{s.name}}</a></td>

        <td style="text-align:right">

          <button type="button" [ngbTooltip]="Edit" (click)="open(content, s)" title="Edit"
            class="btn btn-primary btn-xs">
            <i class="fa fa-edit"></i>
          </button>&nbsp;
          <button type="button" [ngbTooltip]="Delete" (click)="deleteLanguage(s)" title="Delete"
            class="btn btn-danger btn-xs">
            <i class="fa fa-trash"></i>
          </button>&nbsp;


          <span class="pull-right">&nbsp;&nbsp;</span>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="row p-2">
  <app-alert [sender]="sender"></app-alert>
</div>