<div class="form-group">
  <div>
    <h2>Test Schedule Upload</h2>
  </div>
</div>




<div class="form-group">
  <div class="row space-margin">



    <div class="col-md-4">

      <app-search [filter]="filter" (search)="searchTestschedule()"></app-search>


    </div>


    <div class="col-md-6">
      <button class="btn btn-success mr-3  float-right" type="button" data-toggle="modal" (click)="uploadCsrc()">
        <i class="fas fa-upload"></i> Upload
      </button>
      <!-- <button class="btn btn-success mr-3 float-right" type="button" data-toggle="modal" (click)="onAdd()">
        <i class="fa fa-plus"></i> Add
      </button> -->
      <button class="btn btn-danger mr-3 float-right" type="button" data-toggle="modal" (click)="onDelete()">
        <i class="fa fa-trash"></i> Delete All
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="!testschedule|| testschedule.length == 0">
    No test schedule upload data found
  </div>
</div>

<div class="row" *ngIf="testscheduleCount">
  <div class="col-md-3">
    <div style="color: #B5B4B4;">
      <h4 style="color:gray">Total:<span class="badge ng-binding"
          style="font-size: 20px;">{{testscheduleCount || 0}}</span>
      </h4>
      <div class="col-sm-2 pull-left"> </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar height:100px">
    <table class="table table-hover table-outline mb-0" style="background-color: white;" *ngIf="testscheduleCount">
      <thead class="thead-default" style=" background-color: #c2cfd6;">
        <tr>
          <th>Candidate User ID</th>
          <th>Candidate Exam ID</th>
          <th>Test Center ID</th>
          <th>Test Date</th>
          <th>Test Start Time</th>
          <th>Exam Duration (in Min)</th>
          <th>Reporting Duration (in min)</th>
          <th>Gate Closing</th>
        </tr>
      </thead>
      <tr *ngFor="let t of testschedule;" style="cursor:pointer" (click)="OnClick(t._id)">
        <td>{{t.can_userid}}</td>
        <td><a>{{t.can_examid}}</a></td>
        <td><a>{{t.testcenterid}}</a></td>
        <td><a>{{t.testdate}}</a></td>
        <td><a>{{t.teststarttime}}</a></td>
        <td><a>{{t.examduration}}</a></td>
        <td><a>{{t.reportingduration}}</a></td>
        <td><a>{{t.gate_closing}}</a></td>
      </tr>
    </table>
  </div>
</div>

<div class="row" *ngIf="testscheduleCount >= 25">
  <div class="col-md-12">

    <app-pagination [(currentPage)]="currentPage" [pageSize]="pageSize" [totalRecords]="testscheduleCount"
      (pageChanged)="fetchexamData($event)"></app-pagination>
  </div>
</div>


<app-alert [sender]="sender"></app-alert>