import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-cand-data-down',
  templateUrl: './bulk-cand-data-down.component.html',
  styleUrls: ['./bulk-cand-data-down.component.sass']
})
export class BulkCandDataDownComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
